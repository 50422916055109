
import LoadingPage from "@/components/LoadingPage.vue";
import { RouteNames } from "@/enums";
import authFacade from "@/facades/auth";
import { useRouter } from "@/lib/router";
import { helperMixin } from "@/mixins";
import auth from "@/store/auth";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [helperMixin],

  components: {
    LoadingPage,
  },

  data() {
    return {
      loading: true,
      isError: false,
    };
  },

  async mounted() {
    try {
      await this.appStore.fetchConfig();
      document.documentElement.lang = this.appStore.state.lang;

      this.loading = false;
    } catch (e) {
      this.isError = true;
    }
  },

  setup() {
    let passRouteCheck = false;

    auth().watch("token", async (val) => {
      if (!val) {
        passRouteCheck = true;
        useRouter().push({
          name: RouteNames.Login,
        });
      } else {
        const { failed, status } = await authFacade().validateToken();

        if (!failed) {
          passRouteCheck = true;
          useRouter().push({
            name: RouteNames.Dashboard,
          });
        } else if (status === 401) {
          passRouteCheck = true;
          useRouter().push({
            name: RouteNames.Login,
          });
        } else if (status === 403) {
          passRouteCheck = true;
          useRouter().push({
            name: RouteNames.VerifyEmail,
          });
        }
      }
    });

    useRouter().beforeEach(async (to, from, next) => {
      if (passRouteCheck) {
        return next();
      } else {
        // request into guest page like 'login' and 'register'
        // and current token is not null
        if (!to.meta?.isRequireAuth && auth().state.token) {
          // validate token in App/Main route
          passRouteCheck = false;
          return next({
            name: RouteNames.Dashboard,
          });
        }
        // into auth page
        // and validate token
        else if (to.meta?.isRequireAuth) {
          const { failed, status, data } = await authFacade().validateToken();

          if (failed) {
            if (status === 401 || status === 0) {
              auth().revokeToken();

              passRouteCheck = true;

              return next({
                name: RouteNames.Login,
              });
            } else if (status === 403) {
              auth().state.user.email = data?.email;

              passRouteCheck = true;

              return next({
                name: RouteNames.VerifyEmail,
              });
            }
          }
        }

        passRouteCheck = true;

        next(to);
      }
    });
  },
});
