import { RouteNames, UserRole } from "@/enums";
import App from "@/layout/App.vue";
import { ChildrenRecordRaw, RouterOption } from "@/lib/router";

const context = require.context("./auth", true, /\.ts$/i);

const requireAuthRoutes: ChildrenRecordRaw[] = [];

context.keys().forEach((file) => {
  const splitDir = file.split("/");

  let ctx: ChildrenRecordRaw = context(file).default || context(file);

  if (splitDir.length === 3) {
    if (splitDir[1] === "admin") {
      ctx = {
        ...ctx,
        meta: {
          ...ctx.meta,
          userRole: UserRole.Admin,
        },
      };
    } else if (splitDir[1] === "superadmin") {
      ctx = {
        ...ctx,
        meta: {
          ...ctx.meta,
          userRole: UserRole.SuperAdmin,
        },
      };
    }
  }

  requireAuthRoutes.push(ctx);
});

export default [
  {
    path: "/",
    component: App,
    meta: {
      isRequireAuth: true,
    },
    children: requireAuthRoutes,
  },
  {
    path: "/verify_email",
    name: RouteNames.VerifyEmail,
    component: () => import("@/pages/VerifyEmail.vue"),
    meta: {
      isRequireAuth: true,
    },
  },
  {
    path: "/login",
    name: RouteNames.Login,
    component: () => import("@/pages/Guest/Login.vue"),
    meta: {
      isRequireAuth: false,
    },
  },
  {
    path: "/signup",
    name: RouteNames.Register,
    component: () => import("@/pages/Guest/Register.vue"),
    meta: {
      isRequireAuth: false,
    },
  },

  // password reset
  {
    // password reset token diencode menggunakan base64 encoder
    path: "/password_reset/:passwordResetToken?",
    name: RouteNames.PasswordReset,
    component: () => import("@/pages/Guest/PasswordReset.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: RouteNames.Login,
    },
  },
] as RouterOption[];
