
import { helperMixin } from "@/mixins";
import contentTool from "@/store/contentTool";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { defineComponent, ref } from "@vue/runtime-core";
import { handleUwuSearchToggleClick } from "@/template/app";

export default defineComponent({
  mixins: [helperMixin],

  components: {
    BaseButtonPrimary,
    BaseLink,
    Error
  },

  setup() {
    return {
      uwuSearchInput: ref(null as null | HTMLElement)
    };
  },

  computed: {
    contentTool,
    pageTitle() {
      if (!this.appStore.state.activeMenu) return "";

      return (
        (this.appStore.state.activeMenu.parent_title
          ? this.trans(`menu.${this.appStore.state.activeMenu.parent_title}`)
          : null) ?? this.trans(`menu.${this.appStore.state.activeMenu.title}`)
      );
    },
    breadcrumbs() {
      return [
        ...(this.appStore.state.activeMenu?.breadcrumbs ?? []),
        ...(this.currentRoute.meta?.breadcrumbs ?? [])
      ];
    }
  },

  data() {
    return {
      search: ""
    };
  },

  methods: {
    transBreadcrumb(breadcrumb) {
      const trans = this.trans(`layout.breadcrumbs.${breadcrumb}`);

      return trans === `layout.breadcrumbs.${breadcrumb}` ? breadcrumb : trans;
    }
  },

  watch: {
    currentRoute() {
      if (this.search) {
        if (this.uwuSearchInput) {
          handleUwuSearchToggleClick(this.uwuSearchInput);
        }
        this.search = "";
        this.contentTool.state.search = "";
      }

      this.contentTool.state.filterCount = 0;

      let breadCrumbsTitle = "";

      this.breadcrumbs.forEach(val => {
        breadCrumbsTitle +=
          " · " +
          this.makeFirstCapital(this.trans(`layout.breadcrumbs.${val}`));
      });

      this.setPageTitle(
        this.makeFirstCapital(this.pageTitle) + breadCrumbsTitle
      );
    }
  }
});
