
import LoadMoreTableRow from "@/components/Column/LoadMoreTableRow.vue";
import { helperMixin, uwuLoaderDelay } from "@/mixins";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  mixins: [uwuLoaderDelay, helperMixin],
  components: { LoadMoreTableRow },
  emits: ["load-more"],
  computed: {
    uwuLoaderTimeout: () => 0
  },
  props: {
    noSticky: {
      type: Boolean,
      default: true,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    noResultFound: {
      type: Boolean,
      default: false
    },
    noDataAvailable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<"" | "detail" | "border">,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false
    },
    colspan: {
      type: [Number, String],
      required: false
    },
    hasMore: {
      type: Boolean,
      required: false
    }
  },
});
