
import BaseLink from "@/components/Base/BaseLink.vue";
import { helperMixin } from "@/mixins";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  mixins: [helperMixin],
  components: { BaseLink },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object as PropType<import("@/lib/router").GoTo>,
      default: null,
    },
  },
});
