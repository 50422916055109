
import { defineComponent } from "vue";
import authStore from "@/store/auth";
import { Default } from "@/enums";

export default defineComponent({
  computed: {
    companyLogo() {
      return authStore().state.user.company?.logo_link || Default.image;
    }
  }
});
