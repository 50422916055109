import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_user_certificates_download = _resolveComponent("user-certificates-download")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createBlock(_component_base_table, {
    type: "border",
    colspan: "3",
    noDataAvailable: _ctx.noDataAvailable
  }, {
    header: _withCtx(() => [
      _createVNode(_component_base_table_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_base_table_column, {
            "text-align": "center",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.user.certificate")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, {
            "text-align": "center",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.user.no_certificate")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, {
            "text-align": "center",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.user.certificate_expired_date")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certificates, (certificate, i) => {
        return (_openBlock(), _createBlock(_component_base_table_row, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, { "text-align": "center" }, {
              default: _withCtx(() => [
                _createVNode(_component_user_certificates_download, { certificate: certificate }, null, 8, ["certificate"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, { "text-align": "center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(certificate.no_certificate), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, { "text-align": "center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(certificate.expire_date)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["noDataAvailable"]))
}