import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'data')
    ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        class: _normalizeClass([_ctx.textAlign ? 'text-' + _ctx.textAlign : ''])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2))
    : (_ctx.type === 'label')
      ? (_openBlock(), _createElementBlock("th", {
          key: 1,
          class: _normalizeClass([_ctx.textAlign ? 'text-' + _ctx.textAlign : ''])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
}