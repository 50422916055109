
import SideMenuItem from "@/components/App/SideMenuItem.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BaseLink, SideMenuItem },
  mixins: [helperMixin],
});
