
import BaseLink from "@/components/Base/BaseLink.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { BaseLink },
  props: {
    to: {
      type: Object as PropType<import("@/lib/router").GoTo>,
      default: null
    }
  }
});
