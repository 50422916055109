
import { defineComponent, ref } from "vue";
import TopMenuDropdown from "@/components/App/TopMenuDropdown.vue";
import {
  helperMixin,
  infinitySrollJobApplicantByStatus,
  rescheduleResponse,
} from "@/mixins";
import CompanyLogo from "@/components/CompanyLogo.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import echo from "@/lib/echo";
import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { JobApplicantStatus } from "@/enums";
import CustomNotification from "@/lib/customNotification";
import ConfirmModal from "../ConfirmModal.vue";

export default defineComponent({
  mixins: [
    rescheduleResponse,
    helperMixin,
    infinitySrollJobApplicantByStatus([JobApplicantStatus.RescheduleRequest]),
  ],

  components: {
    BaseButtonPrimary,
    TopMenuDropdown,
    CompanyLogo,
    BaseLink,
    AvatarImg,
    ConfirmModal,
  },

  setup() {
    return {
      toggler: ref(null) as unknown as HTMLButtonElement,
    };
  },

  data() {
    return {
      rescheduleRespondEvent: null as null | Function,
      withContentBodyScrollEvent: false,
      channelName: "" as string,
    };
  },

  mounted() {
    this.listenRescheduleRequestEvent();

    this.rescheduleRespondEvent = this.pubSub.on(
      this.EventNames.RescheduleRespond,
      () => this.reload()
    );
  },

  beforeUnmount() {
    this.rescheduleRespondEvent ? this.rescheduleRespondEvent() : "";
    echo.leave(this.channelName);
  },

  methods: {
    listenRescheduleRequestEvent() {
      if (!this.authStore.state.user.company) return;

      this.channelName = `reschedule-request.${this.authStore.state.user.company.id}`;

      echo.private(this.channelName).listen(".save", async (data: any) => {
        this.reload();

        new CustomNotification(
          this.makeFirstCapital(
            this.trans("layout.job_applicant.reschedule_request")
          ),
          {
            body: this.trans(
              "layout.job_applicant.incoming_reschedule_request",
              {
                candidateName: this.capitalize(data.candidate_name),
                date: this.formatDate(data.date),
              }
            ),
          }
        ).addEventListener("click", () => {
          window.focus();
          this.toggler.click();
        });
      });
    },
  },
});
