export enum UserRole {
    SuperAdmin = "super admin",
    Admin = "admin",
    Sailor = "sailor",
}

export enum RouteNames {
    User = "User",
    UserCreate = "UserCreate",

    VerifyEmail = "VerifyEmail",
    Login = "Login",
    Register = "Register",
    PasswordReset = "PasswordReset",

    Dashboard = "Dashboard",

    Job = "Job",
    JobCreate = "JobCreate",
    JobEdit = "JobEdit",
    JobApplicant = 'JobNewApplicant',

    JobApplicantInProcess = "JobApplicantInProcess",
    JobApplicantInterview = "JobApplicantInterview",
    JobApplicantDetail = "JobApplicantDetail",
    // JobApplicantRescheduleRequest = "JobApplicantRescheduleRequest",
    // JobApplicantRejected = "JobApplicantRejected",

    Crew = "Crew",
    CrewDetail = "CrewDetail",

    Fleet = "Fleet",
    FleetCreate = "FleetCreate",
    FleetEdit = "FleetEdit",
    FleetDetail = "FleetDetail",

    Planning = "Planning",

    Setting = "setting",
    AccountSetting = "AccountSetting",
    CompanySetting = "CompanySetting",

    // superadmin page
    Company = "Company",
    CompanyDetail = "CompanyDetail",
    
    Sailor = "Sailor",
    SailorDetail = "SailorDetail",

    Applicant = "Applicant",

    News = "News",
    NewsCreate = "NewsCreate",
    NewsUpdate = "NewsUpdate",
    NewsEdit = "NewsEdit",
}

export enum LocalStorageKeys {
    Token = "_token",
    LastResendEmail = "last_resend_email"
}

export enum EventNames {
    IncomingApplication,
    InterviewRespond,
    RescheduleRespond,
    SaveButtonAppClick,
    CancelButtonAppClick,
    BaseSelectClick,
    FilterButtonClick
}

export enum SelectionType {
    JobHiringNationality = "job_hiring_nationality",
    JobPurpose = "job_purpose",
    JobRequirement = "job_requirement",
    JobPosition = "job_position",
    JobContractType = "job_contract_type",
    JobSalaryType = "job_salary_type",
    JobBenefit = "job_benefit",
    Currency = "currency",
    FleetComunication = "fleet_communication",
    FleetComunicationDetail = "fleet_communication_detail",
}

export enum JobApplicantStatus {
    Sent = "sent",
    InProcess = "in_process",
    
    InterviewInvitation = "interview_invitation",
    Interview = "interview",
    InterviewRejected = "interview_rejected",

    RescheduleRequest = "reschedule_request",
    Rescheduled = "rescheduled",
    RescheduleRejected = "reschedule_rejected",
    RecruitmentRequest = "recruitment_request",

    Rejected = "rejected",
    Hired = "hired",
}

export enum JobApplicantRescheduleAct {
    Reject = "reschedule_reject",
    Accept = "reschedule_accept",
}

export enum JobApplicantInterviewAct {
    Accept = "accept",
    Reject = "reject",
}

export enum Default {
    image = require("@/assets/default_image.png"),
}

export enum DefaultCondition {
    FleetCommunicationNeedDetail = "Tel. No",
}

export enum CrewStatus {
    StandByOnFleet = "stand_by_on_fleet",
    OnBoard = "on_board",
    StandBy = "stand_by",
    Releiver = "releiver"
}

export enum Readliness {
    /**
     * semua sertifikat jauh dari expired.
     */
    Good = "good",
    /**
     * ada sertifikat yang mendekati expired.
     */
    Warning = "warning",
}

export enum Tonnage {
    Gross = "groos",
    Net = "net"
}

export enum Gender {
    Male = "male",
    Female = "female",
}

export enum CrewBoardSaveAct {
    Replacement = "replacement",
    Save = "save",
    Renew = "renew",
}

export enum NotificationType {
    HiringRequestAccepted = "hiring_request_accepted",
    
    HiringRequestRejected = "hiring_request_rejected",

    IncomingApplication = "incoming_application",
}

export enum FilterChartPeriode {
    month = "month",
    day = "day"
}