import { createInstance } from "@/lib/fn";
import { NotificationPaginate, NotificationPaginateResponse } from "@/models/notification";
import BaseResourceHttp, { Excepts } from "@/lib/resourceHttp";
import { BasePaginateFilter, PaginateResponse } from "@/interfaces";

class Notification extends BaseResourceHttp<NotificationPaginate, any, any> {
    protected ignoreMethods: Array<Excepts> = ["create", "update", "delete"];

    protected baseURL(): string {
        return "notification";
    }

    public markAllAsRead() {
        return this.request("POST", this.url(`read_all`));
    }

    public markAsRead(id: any) {
        return this.request("POST", this.url(`read/${id}`));
    }

    public async paginate(filter?: BasePaginateFilter): Promise<NotificationPaginateResponse> {
        const { response, status, message } =  await this.request('GET', this.baseURL(), {
            params: filter
        });

        return {
            message,
            status,
            data: response.data ?? [],
            has_more: response.has_more,
            total: response.total,
            unread: response.unread
        };
    }
}

export default createInstance<Notification>(Notification);