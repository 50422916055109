import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";

export default createBaseChildrenRecordRaw('job', {
    index: {
        name: RouteNames.Job,
        component: () => import("@/pages/Admin/Job/Index.vue"),
        meta: {
            withFilter: true
        }
    },
    create: {
        name: RouteNames.JobCreate,
        component: () => import("@/pages/Admin/Job/Save.vue"),
    },
    edit: {
        name: RouteNames.JobEdit,
        component: () => import("@/pages/Admin/Job/Save.vue"),
    },
    children: [
        {
            path: ":id/new_applicant",
            name: RouteNames.JobApplicant,
            component: () => import("@/pages/Admin/Job/NewApplicant.vue"),
            meta: {
                breadcrumbs: ["new_applicant"],
                withBackButton: true,
                withContentFooter: true
            }
        }
    ]
});