
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<import("@/types").BaseTableColumnType>,
      default: "data"
    },

    textAlign: {
      type: String as PropType<"left" | "center" | "right">,
      required: false
    }
  }
});
