import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.to)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        class: "uwu-dropdown-item",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        class: "uwu-dropdown-item",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
        to: _ctx.to
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to"]))
}