import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["uwu-badge", [
    _ctx.color === 'danger' ? 'text-red-500' : '',
    _ctx.color === 'warning' ? 'text-yellow-600' : '',
    _ctx.color === 'info' ? 'text-blue-500' : '',
    _ctx.color === 'primary' ? 'text-primary' : '',
    _ctx.color === 'success' ? 'text-green-500' : '',
    !_ctx.color ? 'text-deep' : '',
  ]])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}