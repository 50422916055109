import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_more_table_row = _resolveComponent("load-more-table-row")!

  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(["uwu-table", [
      _ctx.type === 'border' ? 'uwu-table-border' : '',
      _ctx.type === 'detail' ? 'uwu-table-detail' : '',
      _ctx.type === 'detail' ? 'uwu-table-detail' : '',
      _ctx.noSticky ? 'uwu-table-no-sticky' : '',
      _ctx.hover ? 'uwu-table-hover' : ''
    ]])
  }, [
    _createElementVNode("thead", null, [
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createElementVNode("tbody", null, [
      (!_ctx.isLoading)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true),
      (_ctx.noResultFound)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
            _createElementVNode("td", {
              class: "text-center",
              colspan: _ctx.colspan
            }, _toDisplayString(_ctx.trans('layout.no_result_found')), 9, _hoisted_2)
          ]))
        : (_ctx.noDataAvailable)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
              _createElementVNode("td", {
                class: "text-center",
                colspan: _ctx.colspan
              }, _toDisplayString(_ctx.trans('layout.no_data_available')), 9, _hoisted_4)
            ]))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("tfoot", null, [
      _renderSlot(_ctx.$slots, "footer"),
      (_ctx.type !== 'detail')
        ? (_openBlock(), _createBlock(_component_load_more_table_row, {
            key: 0,
            loading: _ctx.isLoading,
            "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
            colspan: _ctx.colspan,
            "has-more": _ctx.hasMore,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('load-more')))
          }, null, 8, ["loading", "colspan", "has-more"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}