import { PaginateResponse } from "@/interfaces";
import { createInstance } from "@/lib/fn";
import BaseResourceHttp, { Excepts } from "@/lib/resourceHttp";
import { CrewAddToBoard, CrewPaginate, CrewPaginateFilter } from "@/models/crew";

class Crew extends BaseResourceHttp<CrewPaginate, any, any> {
    protected ignoreMethods: Array<Excepts> = ["create", "update", "delete"];

    protected baseURL(): string {
        return "crew";
    }

    addToBoard(data: CrewAddToBoard) {
        return this.request("POST", `${this.baseURL()}/add_to_board`, {
            data: {
                fleet_id: data.fleet_id,
                crews: data.crews
            }
        });
    }

    async paginate(filter?: CrewPaginateFilter): Promise<PaginateResponse<CrewPaginate[]>> {
        return super.paginate({
            ...filter,
            position_in: filter?.position_in?.join(","),
        } as CrewPaginateFilter);
    }
}

export default createInstance<Crew>(Crew);