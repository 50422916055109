
import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import { CrewBoardSaveAct, CrewStatus } from "@/enums";
import crewHttp from "@/http/crew";
import crewBoardHttp from "@/http/crewBoard";
import { baseModal, helperMixin, saveAndUpdate } from "@/mixins";
import { CrewPaginate, CrewPaginateFilter } from "@/models/crew";
import { CrewBoardSave } from "@/models/crewBoard";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    BaseModal,
    BaseFormGroup,
    BaseButtonDanger,
    BaseButtonPrimary,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    AvatarImg,
    BaseFormApp,
  },

  mixins: [
    helperMixin,
    baseModal,
    saveAndUpdate<CrewBoardSave, any>(crewBoardHttp()),
  ],

  emits: ["done", "update:crewBoard", "update:modelValue"],

  props: {
    crewBoard: {
      type: Object as PropType<import("@/models/crewBoard").CrewBoardPaginate>,
      required: true,
    },
  },

  computed: {
    crewHttp,
    redirectAfterSubmit() {
      return false;
    },
  },

  data() {
    return {
      crew: null as null | CrewPaginate,
      crewSelectFilter: {
        status: CrewStatus.StandBy,
      } as CrewPaginateFilter,
      replacedCrewText: `${this.crewBoard.crew.crew_id} - ${this.crewBoard.crew.job_applicant.user.fullname}`,
    };
  },

  methods: {
    afterSubmit() {
      this.$emit("update:crewBoard", null);
      this.$emit("update:modelValue", false);
    },

    onCrewChange(crew: CrewPaginate) {
      this.crew = crew;
    },

    onModalHide() {
      this.$refs.switchCrewSelect?.resetSelect();
      this.validationErrors = {};
    },

    setForm() {
      this.form.act = CrewBoardSaveAct.Replacement;
      this.form.old_crew_board_id = this.crewBoard.id;
      this.form.sign_on = this.crewBoard.sign_off;
      this.form.sign_off = "";
      this.form.crew_id = "";
      this.crew = null;
    },
  },
});
