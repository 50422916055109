import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center text-base"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page = _resolveComponent("loading-page")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.isError)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Oops, something went wrong. "))
    : (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading_page, { key: 1 }))
      : (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
}