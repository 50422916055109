import { RouteNames } from '@/enums'
import { GoTo } from '@/lib/router'
import app from '@/store/app'
import numeral from 'numeral'
import { LocationQuery } from 'vue-router'

export const replaceWithSpace = (val: string, delimiter = "_") => {
    return val.replace(new RegExp(`\\${delimiter}`, "g"), " ");
};

export const capitalize = (val: string) => {
    if (!val) return "";

    let text = "";

    const splitVal = val.split(" ");

    for (let i = 0; i < splitVal.length; i++) {
        text += " " + makeFirstCapital(splitVal[i]);
    }

    return text.trim();
};

export const makeFirstCapital = (val: string) => {
    return val.charAt(0).toUpperCase() + val.substring(1);
};

export const randomString = () => {
    return Math.random().toString(36).substr(2);
};

export const trans = (key: string, replace?: object, obj: object | null = null, realKey?: string): string => {
    const o = Object(obj ?? app().state.translations);

    let keys: string[] = key.split(".");

    if (!o[keys[0]]) return realKey || "";

    // found!
    if (typeof o[keys[0]] === "string") {
        let str = o[keys[0]] as string;

        for (let replaceKey in replace) {
            str = str.replace(`:${replaceKey}`, replace[replaceKey] || '');
        }

        return makeFirstCapital(str);
    } else {
        
    }

    // recursion until found
    return trans(keys.slice(1).join("."), replace, o[keys[0]], realKey || key);
};

export const goTo = (name: RouteNames, opt?: { params?: { id?: any, passCheck?: number }, query?: LocationQuery }): GoTo => {
    return {
        name,
        ...opt
    };
};

export const unformatNumber = (val: string) => {
    return parseInt(String(val).replace(/\./g, ""));
};

export const numberFormat = (arg: KeyboardEvent | string) => {
    if (arg instanceof Event) {
        const target: HTMLInputElement = arg.target as HTMLInputElement;

        if (!Number.isInteger(parseInt(arg.key))) {
            target.value = String(target.value).replace(/[^0-9]/g, '');
        }

        if (target.value) {
            target.value = String(numeral(unformatNumber(target.value)).format("0,0"))
                .replace(/\,/g, ".");
        }
    } else if (arg) {
        return String(numeral(unformatNumber(arg)).format("0,0"))
            .replace(/\,/g, ".");
    }
};

export const indexOfClass = (collections?: NodeList, node?: HTMLElement | Node) => {
    if (!collections) return -1;

    for (let i = 0; i < collections.length; i++) {
        if (collections[i] == node) {
            return i;
        }
    }

    return -1;
};