import app from "@/store/app";

export default class CustomNotification extends Notification {
    constructor(title: string, options?: NotificationOptions) {
        if (Notification.permission === "default") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    window.location.reload();
                }
            });
        } else {
            super(title, { ...options, lang: app().state.lang });
        }
    }
}