
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    align: {
      type: String as PropType<"left" | "right">,
      default: "right",
    },
  },
});
