
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import { helperMixin, uwuLoaderDelay } from "@/mixins";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BaseTableRow, BaseTableColumn, BaseButtonPrimary },
  mixins: [helperMixin, uwuLoaderDelay],
  emits: ["click"],
  props: {
    colspan: {
      type: String
    },
    hasMore: {
      type: Boolean
    }
  }
});
