import { createInstance } from '@/lib/fn'
import Http, { HttpResponse } from '@/lib/http'
import { CompanyDetail, CompanySave } from '@/models/company'
import { UserAuth, UserAuthUpdate } from '@/models/user'
import { State } from '@/store/auth'

export interface LoginForm {
    email: string;
    password: string;
}

export interface RegisterForm extends LoginForm {
    role: string;
    name: string;
    siupal_or_siuppak: string;
    phone_number: number;
    address: string;
    website: string;
    password_confirmation: string;
}

export interface ResetPasswordForm {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}

export interface AuthResponse extends Omit<HttpResponse, "response"> {
    data: State;
}

export class Auth extends Http {
    updateCompany(data: CompanySave): Promise<Omit<HttpResponse, "response"> & { response: { data: CompanyDetail } }> {
        return this.request("PUT", "auth/company", {
            data
        });
    }

    updateUser(data: UserAuthUpdate): Promise<Omit<HttpResponse, "response"> & { response: { data: UserAuth } }> {
        return this.request("PUT", "auth/user", {
            data
        });
    }

    getUser() {
        return this.request("get", "auth/user");
    }

    resendEmailVerification() {
        return this.request("POST", "email/resend");
    }

    // user menginput pasword baru
    resetPassword(data: ResetPasswordForm) {
        return this.request("POST", "reset_password", {
            data
        });
    }

    // saat udah dapet token, user klik linknya, divalidasi oleh sistem
    validatePasswordResetToken(token: string, email: string) {
        return this.request("POST", `validate_password_reset_token/${token}/${email}`);
    }

    // user meminta link reset password 
    sendPasswordResetLink(email: string) {
        return this.request("POST", "send_password_reset_link", {
            data: {
                email
            }
        });
    }

    async login(data: LoginForm): Promise<AuthResponse> {
        const { headers, response, status, message, validationErrors } = await this.request("POST", "login", { data: data });

        return {
            headers,
            message,
            status: status,
            data: response.data,
            validationErrors
        };
    }

    async register(data: RegisterForm): Promise<AuthResponse> {
        data.role = "admin";

        const { headers, response, status, message, validationErrors } = await this.request("POST", "register", { data: data });
        

        return {
            headers,
            message,
            status: status,
            data: response.data,
            validationErrors
        };
    }
    
    logout() {
        return this.request("POST", "logout");
    }
}

export default createInstance<Auth>(Auth);