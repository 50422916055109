import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";

export default createBaseChildrenRecordRaw('news', {
    index: {
        name: RouteNames.News,
        component: () => import("@/pages/Superadmin/News/Index.vue")
    },
    create: {
        name: RouteNames.NewsCreate,
        component: () => import("@/pages/Superadmin/News/Save.vue"),
    },
    edit: {
        name: RouteNames.NewsEdit,
        component: () => import("@/pages/Superadmin/News/Save.vue"),
    }
});