
import { defineComponent, PropType, reactive } from "vue";
import appStore from "@/store/app";

export default defineComponent({
  emits: ["click"],
  computed: {
    appStore,
  },
  props: {
    to: {
      type: Object as PropType<import("@/lib/router").GoTo | null>
    }
  },
  methods: {
    onClick(e: Event) {
      if (this.appStore.state.currKey) return;
      
      this.$emit("click", e);

      if (this.to) {
        // di set false di @/layout/App.vue
        this.appStore.state.loadingRoute = true;
      }
    }
  },
});
