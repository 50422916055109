
import BaseLink from "@/components/Base/BaseLink.vue";
import { helperMixin } from "@/mixins";
import { Sidebar, toast } from "@/template/app";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  mixins: [helperMixin],
  props: {
    rootMenuPermission: Number as PropType<1 | 0>,
    menu: {
      type: Object as PropType<import("@/interfaces").Menu>,
      required: true
    }
  },
  components: { BaseLink },
  computed: {
    hide() {
      return !(
        this.appStore.state.activeMenu?.is_setting_menu ==
        this.menu.is_setting_menu
      );
    },
    isActive() {
      return (
        this.appStore.state.activeMenu?.vue_router_name ===
        this.menu.vue_router_name
      );
    },
    disabled() {
      return (
        this.appStore.state.loadingRoute ||
        this.appStore.state.activeMenu?.title === this.menu.title ||
        !this.rootMenuPermission
      );
    }
  },
  data() {
    return {
      Sidebar
    };
  },
  methods: {
    setActiveMenu() {
      if (!this.rootMenuPermission) {
        toast(
          "warning",
          this.trans("layout.access_menu_denied", {
            menuName: this.trans(`menu.${this.menu.parent_title ?? this.menu.title}`)
          }),
          0
        );
        return;
      }

      this.appStore.state.activeMenu = this.menu;
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler(val) {
        if (val) {
          this.Sidebar.removeParentMenuOpenActive();
          this.$nextTick(() => {
            if (this.appStore.state.activeMenu) {
              this.appStore.state.activeMenu.permission = this.rootMenuPermission as any;
            }
            this.Sidebar.initActiveMenu();
          });
        }
      }
    }
  }
});
