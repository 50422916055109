import { createInstance } from "@/lib/fn";
import Store from "@/lib/store";

interface State {
    search: string;
    filterCount: 0;
}

class ContentTool extends Store<State> {
    protected states(): State {
        return {
            search: "",
            filterCount: 0,
        };
    }
}

export default createInstance<ContentTool>(ContentTool);