import authHttp, { AuthResponse, LoginForm, RegisterForm } from "@/http/auth";
import { createInstance } from "@/lib/fn";
import auth from "@/store/auth";

export enum AttemptType {
  Login,
  Register
}

export type AttemptFormData<T extends AttemptType> = T extends AttemptType.Login
  ? LoginForm
  : T extends AttemptType.Register
  ? RegisterForm
  : null;

class AuthFacade {
  /**
   * Get access token from spesific request
   */
  async attempt<T extends AttemptType>(
    type: T,
    formData: AttemptFormData<T>
  ): Promise<{ failed: boolean; message: string; validationErrors: any }> {
    let response: AuthResponse = {} as AuthResponse;

    if (type === AttemptType.Login) {
      response = await authHttp().login(formData as LoginForm);
    } else if (type === AttemptType.Register) {
      response = await authHttp().register(
        (formData as unknown) as RegisterForm
      );
    }

    if (response.status === 200 && response.data.token && response.data.user) {
      try {
        auth().attempt(response.data);

        return Promise.resolve({
          failed: false,
          message: response.message,
          validationErrors: response.validationErrors
        });
      } catch (e) {
        return Promise.resolve({
          failed: true,
          message: <string>e,
          validationErrors: response.validationErrors
        });
      }
    }

    return Promise.resolve({
      failed: true,
      message: response.message,
      validationErrors: response.validationErrors
    });
  }

  async validateToken(): Promise<{
    failed: boolean;
    status: number;
    data?: any;
  }> {
    if (!auth().state.token) {
      return Promise.resolve({
        failed: true,
        status: 0
      });
    }

    const { status, response } = await authHttp().getUser();

    if (status !== 200) {
      return Promise.resolve({
        failed: true,
        status: <number>status,
        data: response.data
      });
    }

    auth().state.user = response.data;

    return Promise.resolve({
      failed: false,
      status
    });
  }
}

export default createInstance<AuthFacade>(AuthFacade);
