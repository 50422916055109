import { PaginateResponse } from '@/interfaces'
import { createInstance } from '@/lib/fn'
import BaseResourceHttp, { Excepts } from '@/lib/resourceHttp'
import { CrewBoardPaginate, CrewBoardPaginateFilter, CrewBoardSave, SeaServicePaginateFilter } from '@/models/crewBoard'

class CrewBoard extends BaseResourceHttp<
  CrewBoardPaginate,
  CrewBoardSave,
  CrewBoardSave
> {
  protected ignoreMethods: Array<Excepts> = ["delete"];

  protected baseURL(): string {
    return "crew_board";
  }

  async exportPlanning(filter?: CrewBoardPaginateFilter): Promise<void> {
    const { response, headers, status } = await this.request(
      "POST",
      this.url("export_planning"),
      {
        responseType: "arraybuffer",
        data: filter,
      }
    );

    if (status === 500) {
      throw "INTERNAL SERVER ERROR";
    }

    const blob = new Blob([response], { type: headers["content-type"] });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = "planning_" + new Date().toISOString() + ".xlsx";
    link.click();

    window.URL.revokeObjectURL(link.href);
  }

  async paginate(
    filter?: CrewBoardPaginateFilter
  ): Promise<PaginateResponse<CrewBoardPaginate[]>> {
    return super.paginate({
      ...filter,
    } as CrewBoardPaginateFilter);
  }

  async historyByUser(
    filter?: SeaServicePaginateFilter
  ): Promise<PaginateResponse<CrewBoardPaginate[]>> {
    const { response, status, message } = await this.request(
      "GET",
      this.url(`history_by_user`),
      {
        params: filter,
      }
    );

    return {
      message,
      status,
      data: response.data ?? [],
      has_more: response.has_more,
      total: response.total,
    };
  }
}

export default createInstance<CrewBoard>(CrewBoard);
