import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rounded-lg px-2.5 py-1 bg-default text-sm capitalize"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_link = _resolveComponent("base-link")!

  return (!_ctx.to)
    ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createBlock(_component_base_link, {
        key: 1,
        class: "rounded-lg px-2.5 py-1 bg-default text-sm capitalize",
        to: _ctx.to
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to"]))
}