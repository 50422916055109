import { RouteNames } from "@/enums";
import { ChildrenRecordRaw } from "@/lib/router";
import { RouterView } from "vue-router";

export default {
    path: "account",
    name: RouteNames.AccountSetting,
    component: () => import("@/pages/AccountSetting.vue"),
    meta: {
        withContentFooter: true
    }
} as ChildrenRecordRaw;