
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseMessageError from "@/components/Base/BaseMessageError.vue";
import BaseRadio from "@/components/Base/BaseRadio.vue";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import { indexOfClass } from "@/helpers";
import { helperMixin, twoStateBindValue } from "@/mixins";
import { DefineComponent, defineComponent, PropType, ref } from "vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  emits: ["select-change", "add-button-click"],

  mixins: [helperMixin, twoStateBindValue],

  components: {
    VueDatePicker,
    BaseMessageError,
    BaseCheckbox,
    BaseRadio,
    BaseInput,
    BaseSelect,
  },

  props: {
    maxDate: {
      default: "",
    },
    minDate: {
      default: "",
    },
    withSelectAddButton: {
      type: Boolean,
      default: false,
    },
    removedSelectedItem: {
      type: Function as PropType<
        (item: import("@/interfaces").BaseItem) => boolean
      >,
      required: false,
    },

    selectKeyValue: {
      type: String,
      default: "value",
    },

    selectKeyText: {
      type: String,
      default: "text",
    },

    customClassLabel: {
      type: String,
      default: "",
    },

    stickyLabel: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    inputType: {
      type: String as PropType<
        | "text"
        | "password"
        | "price"
        | "date"
        | "datetime"
        | "textarea"
        | "checkbox"
        | "radio"
        | "select"
        | "multi-select"
        | "custom"
      >,
      default: "text",
    },

    label: {
      type: String,
      default: "",
    },

    invalidMessagePosition: {
      type: String as PropType<"afterLabel" | "afterField">,
      default: "afterField",
    },

    invalidMessage: {
      type: Array,
      default: () => [],
    },

    /** item for checkbox/radio/select input */
    items: {
      type: Array as PropType<import("@/interfaces").BaseItem[]>,
      default: () => [],
    },

    filterPaginate: {
      type: Object as PropType<
        Omit<import("@/interfaces").BasePaginateFilter, "search" | "page">
      >,
      default: null,
    },

    resourceHttp: {
      type: Object as PropType<
        import("@/lib/resourceHttp").default<any, any, any>
      >,
      default: null,
    },
  },

  setup() {
    return {
      root: ref(null) as unknown as HTMLElement,
      messageError: ref(null) as unknown as DefineComponent,
    };
  },

  computed: {
    indexMessageInForm() {
      return indexOfClass(
        this.root
          ?.closest("body")
          ?.getElementsByClassName(
            this.messageError.$el.className
          ) as unknown as NodeList,
        this.messageError.$el
      );
    },
    errorMessage() {
      return this.invalidMessage?.[0] ?? "";
    },
  },

  mounted() {
    if (this.inputType === "radio") {
      this.inputValue =
        this.modelValue ?? this.items.find((item) => item.default)?.value;
    }
  },

  watch: {
    errorMessage(val) {
      this.$nextTick(() => {
        if (val && this.indexMessageInForm === 0) {
          this.root?.scrollIntoView({
            behavior: "smooth",
          });
        }
      });
    },
  },

  methods: {
    resetSelect() {
      this.$refs?.select?.reset();
    },
  },
});
