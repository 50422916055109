import { JobApplicantInterviewAct, JobApplicantRescheduleAct } from "@/enums";
import { PaginateResponse } from "@/interfaces";
import { createInstance } from "@/lib/fn";
import { HttpResponse } from "@/lib/http";
import BaseResourceHttp, { Excepts } from "@/lib/resourceHttp";
import { JobApplicantPaginate, JobApplicantPaginateFilter, JobApplicantSaveSchedules } from "@/models/jobApplicant";

class JobApplicant extends BaseResourceHttp<JobApplicantPaginate, any, any> {
    protected ignoreMethods: Array<Excepts> = ["create", "update", "delete"];

    protected baseURL(): string {
        return "job_applicant";
    }

    process(candidate_ids: any[]): Promise<HttpResponse> {
        return this.request("POST", this.url("process"), {
            data: {
                candidate_ids
            }
        });
    }

    rejects(candidate_ids: any[]): Promise<HttpResponse> {
        return this.request("POST", this.url("rejects"), {
            data: {
                candidate_ids
            }
        });
    }

    acceptOrReject(id: any, act: JobApplicantInterviewAct): Promise<HttpResponse> {
        return this.request("POST", this.url(`accept_or_reject/${id}`), {
            data: {
                status: act
            }
        });
    }

    rescheduleResponse(id: any, act: JobApplicantRescheduleAct): Promise<HttpResponse> {
        return this.request("POST", this.url(`${act}/${id}`));
    }

    createSchedules(candidates: JobApplicantSaveSchedules[]): Promise<HttpResponse> {
        return this.request("POST", this.url('create_schedules'), {
            data: {
                candidates
            }
        });
    }

    async paginate(filter?: JobApplicantPaginateFilter): Promise<PaginateResponse<JobApplicantPaginate[]>> {
        return super.paginate({
            ...filter,
            status_in: filter?.status_in?.join(",")
        } as JobApplicantPaginateFilter);
    }
}

export default createInstance<JobApplicant>(JobApplicant);