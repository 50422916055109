import { LocalStorageKeys, UserRole } from '@/enums'
import LocalStorage from '@/lib/customStorage'
import { createInstance } from '@/lib/fn'
import Store from '@/lib/store'
import { UserAuth } from '@/models/user'

export interface State {
    user: UserAuth;
    token: string | null;
}

export interface WatchTokenCallback {
    (val: string | null): void;
}

class Auth extends Store<State> {
    protected subsToken: Array<WatchTokenCallback> = [];

    protected states(): State {
        return {
            token: LocalStorage.getInstance().get(LocalStorageKeys.Token),
            user: {} as UserAuth,
        };
    }

    constructor() {
        super();

        LocalStorage.getInstance().addEventListener(LocalStorageKeys.Token, (val) => {
            this.state.token = val;
        });
    }

    revokeToken() {
        LocalStorage.getInstance().remove(LocalStorageKeys.Token);
        this.state.token = null;
        this.state.user = {} as UserAuth;
    }

    attempt(s: State): void {
        if (!s.token && s.user) {
            throw new Error("unable to attempt auth");
        }

        this.state.token = s.token;
        this.state.user = s.user;

        LocalStorage.getInstance().set(LocalStorageKeys.Token, s.token as string);
    }

    getAuthrizationHeader() {
        return `Bearer ${this.state.token ?? ''}`;
    }

    authRoleCheck(role: UserRole) {
        return this.state.user?.role === role;
    }
}

export default createInstance<Auth>(Auth);