import { RouteNames, UserRole } from '@/enums'
import routes from '@/routes'
import {
    createRouter,
    createWebHistory,
    LocationQueryRaw,
    NavigationFailure,
    Router as VueRouter,
    RouteRecordRaw,
} from 'vue-router'

export interface Meta {
    userRole?: UserRole;
    isRequireAuth?: boolean;
    /**
     * useful for validate current active menu
     */
    rootName?: RouteNames;
    /**
     * for create page route
     * when click "new" button
     */
    createName?: RouteNames;
    /**
     * is the page using a content tool?
     */
    withContentTools?: boolean;
    /**
     * is the page using a content footer?
     */
    withContentFooter?: boolean;
    withFilter?: boolean;
    /**
     * it will show in content breadcrumbs
     */
    breadcrumbs?: string[];
    pageTitle?: string;
    withBackButton?: boolean;
    passCheck?: number;
}

export interface RouteRecordOption {
    path?: string;
    name?: RouteNames;
    params?: {
        id?: number,
        passwordResetToken?: string
    };
    meta?: Meta;
    query?: LocationQueryRaw;
}

export type GoTo = RouteRecordOption;

export type Childrens = Omit<RouteRecordRaw & RouteRecordOption & { name: RouteNames }, ''>[];

export type ChildrenRecordRaw = Omit<RouteRecordRaw, "children"> & {
    name?: RouteNames,
    children?: Childrens,
    meta?: Omit<Meta, "isRequireAuth"> & {
        rootName?: RouteNames,
        withContentTools?: boolean
    }
};

export type RouterOption = RouteRecordRaw & RouteRecordOption & {
    path?: string,
    meta: Meta,
    children?: ChildrenRecordRaw[],
};

export interface Router extends Omit<VueRouter, "currentRoute" | "beforeEach" | "replace"> {
    push(to: GoTo): Promise<NavigationFailure | void | undefined>;
    readonly currentRoute: RouterOption;
    replace(to: GoTo): void;
    beforeEach(arg: (to: RouterOption, from: RouterOption, next: { (to?: GoTo): void }) => void): void;
}

const router = createRouter({
    history: createWebHistory(),
    routes
});

export function useRouter(): Router {
    return <any>router;
}

export default router;