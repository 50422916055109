import { RouteNames } from "@/enums";
import { ChildrenRecordRaw } from "@/lib/router";

export default {
    path: "/applicant",
    name: RouteNames.Applicant,
    component: () => import("@/pages/Superadmin/Applicant.vue"),
    meta: {
        withContentTools: true,
        rootName: RouteNames.Applicant
    }
} as ChildrenRecordRaw;