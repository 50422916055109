import { RouteNames } from "@/enums";
import { ChildrenRecordRaw } from "@/lib/router";
import { RouterView } from "vue-router";

export default {
    path: "/sailor",
    component: RouterView,
    redirect: {
        name: RouteNames.Sailor,
    },
    meta: {
        rootName: RouteNames.Sailor
    },
    children: [
        {
            path: "",
            name: RouteNames.Sailor,
            component: () => import("@/pages/Superadmin/Sailor.vue"),
            meta: {
                withFilter: true,
                withContentTools: true,
            }
        },
        {
            path: ":id",
            name: RouteNames.SailorDetail,
            component: () => import("@/pages/Admin/Crew/Detail.vue"),
            meta: {
                breadcrumbs: ["detail"],
                withContentFooter: true
            }
        }
    ]
} as ChildrenRecordRaw;