import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "uwu-base-form-group",
  ref: "root"
}
const _hoisted_2 = { class: "flex items-center gap-1.5" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-500 mx-0.5"
}
const _hoisted_4 = { class: "w-full py-1.5" }
const _hoisted_5 = ["readonly"]
const _hoisted_6 = {
  key: 3,
  class: "flex items-center gap-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_message_error = _resolveComponent("base-message-error")!
  const _component_base_select = _resolveComponent("base-select")!
  const _component_base_checkbox = _resolveComponent("base-checkbox")!
  const _component_base_radio = _resolveComponent("base-radio")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        _ctx.stickyLabel
          ? 'relative top-0 sticky top-0 py-3 z-10 border border-t-0 border-l-0 border-r-0 bg-secondary'
          : '',
      ])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "beforeLabel"),
        _createElementVNode("label", {
          class: _normalizeClass(["capitalize font-semibold", [_ctx.customClassLabel, _ctx.errorMessage ? 'text-red-400' : '']])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ], 2),
        _renderSlot(_ctx.$slots, "afterLabel")
      ]),
      (_ctx.errorMessage && _ctx.invalidMessagePosition === 'afterLabel')
        ? (_openBlock(), _createBlock(_component_base_message_error, {
            key: 0,
            ref: "messageError"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.inputType === 'textarea')
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 0,
            readonly: _ctx.readonly,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            rows: "10",
            class: _normalizeClass(["border focus:border-theme outline-none rounded w-full py-1.5 px-3", _ctx.errorMessage ? 'border-red-300' : ''])
          }, null, 10, _hoisted_5)), [
            [_vModelText, _ctx.inputValue]
          ])
        : (_ctx.inputType === 'select' || _ctx.inputType === 'multi-select')
          ? (_openBlock(), _createBlock(_component_base_select, {
              key: 1,
              ref: "select",
              "key-value": _ctx.selectKeyValue,
              "key-text": _ctx.selectKeyText,
              resourceHttp: _ctx.resourceHttp,
              "filter-paginate": _ctx.filterPaginate,
              readonly: _ctx.readonly,
              items: _ctx.items,
              multiple: _ctx.inputType === 'multi-select',
              removedSelectedItem: _ctx.removedSelectedItem,
              "select-class": _ctx.errorMessage ? 'border-red-300' : '',
              modelValue: _ctx.inputValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
              onChange: _cache[2] || (_cache[2] = (val) => _ctx.$emit('select-change', val)),
              onAddButtonClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('add-button-click'))),
              withAddButton: _ctx.withSelectAddButton
            }, {
              itemTools: _withCtx(({ item }) => [
                _renderSlot(_ctx.$slots, "selectItemTools", { item: item })
              ]),
              _: 3
            }, 8, ["key-value", "key-text", "resourceHttp", "filter-paginate", "readonly", "items", "multiple", "removedSelectedItem", "select-class", "modelValue", "withAddButton"]))
          : (_ctx.inputType === 'checkbox')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.items, (item, i) => {
                return (_openBlock(), _createBlock(_component_base_checkbox, {
                  readonly: _ctx.readonly,
                  key: i,
                  label: item.text,
                  value: item.value,
                  modelValue: _ctx.inputValue,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputValue) = $event))
                }, null, 8, ["readonly", "label", "value", "modelValue"]))
              }), 128))
            : (_ctx.inputType === 'radio')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, j) => {
                    return (_openBlock(), _createBlock(_component_base_radio, {
                      readonly: _ctx.readonly,
                      key: j,
                      label: item.text,
                      value: item.value,
                      modelValue: _ctx.inputValue,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inputValue) = $event))
                    }, null, 8, ["readonly", "label", "value", "modelValue"]))
                  }), 128))
                ]))
              : (_ctx.inputType === 'custom')
                ? _renderSlot(_ctx.$slots, "default", { key: 4 })
                : (_ctx.inputType === 'price')
                  ? (_openBlock(), _createBlock(_component_base_input, {
                      key: 5,
                      readonly: _ctx.readonly,
                      type: "text",
                      class: _normalizeClass(_ctx.errorMessage ? 'border-red-300' : ''),
                      modelValue: _ctx.inputValue,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inputValue) = $event)),
                      onKeyup: _ctx.numberFormat
                    }, null, 8, ["readonly", "class", "modelValue", "onKeyup"]))
                  : (_ctx.inputType === 'date' || _ctx.inputType === 'datetime')
                    ? (_openBlock(), _createBlock(_component_VueDatePicker, {
                        key: 6,
                        "max-date": _ctx.maxDate,
                        "min-date": _ctx.minDate,
                        transition: false,
                        teleport: true,
                        "auto-apply": "",
                        modelValue: _ctx.inputValue,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.inputValue) = $event)),
                        "enable-time-picker": _ctx.inputType === 'datetime',
                        "month-change-on-scroll": false,
                        "is-24": "",
                        "hide-offset-dates": "",
                        class: _normalizeClass(_ctx.errorMessage ? 'border-red-300' : ''),
                        "model-type": 
          _ctx.inputType === 'datetime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
        ,
                        format: _ctx.inputType === 'datetime' ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy'
                      }, null, 8, ["max-date", "min-date", "modelValue", "enable-time-picker", "class", "model-type", "format"]))
                    : (_openBlock(), _createBlock(_component_base_input, {
                        key: 7,
                        readonly: _ctx.readonly,
                        type: _ctx.inputType,
                        class: _normalizeClass(_ctx.errorMessage ? 'border-red-300' : ''),
                        modelValue: _ctx.inputValue,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.inputValue) = $event))
                      }, null, 8, ["readonly", "type", "class", "modelValue"])),
      (_ctx.errorMessage && _ctx.invalidMessagePosition === 'afterField')
        ? (_openBlock(), _createBlock(_component_base_message_error, {
            key: 8,
            ref: "messageError"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ])
  ], 512))
}