import { FilterChartPeriode } from "@/enums";
import { BasePaginateFilter, PaginateResponse, ResourceHttp } from "@/interfaces";
import Http, { HttpResponse, ValidationErrors } from "@/lib/http";

export type Excepts = Extract<keyof ResourceHttp, string>;

export type FilterChart = {
    periode: FilterChartPeriode,
    month?: number, // fill this if periode is day
    year: number
};

export default abstract class BaseResourceHttp<Paginate, Create, Update> extends Http implements ResourceHttp {
    public validationErrors: ValidationErrors<any> = {};

    protected ignoreMethods: Array<Excepts> = [];

    protected abstract baseURL(): string;

    constructor() {
        super();

        return new Proxy(this, {
            get: function (self, key: any) {
                if (typeof self[key] === "function" && self.ignoreMethods.includes(key)) {
                    throw new Error(`method ${key} is not implemented`);
                }

                return key in self ? self[key] : console.log("Access to non-existent property '" + key + "'");
            }
        });
    }

    public url(path: string) {
        return `${this.baseURL()}/${path}`;
    }

    async count(): Promise<number> {
        const { response } = await this.request("GET", this.url("count"));

        return response.data || 0;
    }

    async paginate(filter?: BasePaginateFilter): Promise<PaginateResponse<Paginate[]>> {
        const { response, status, message } = await this.request('GET', this.baseURL(), {
            params: filter
        });

        return {
            message,
            status,
            data: response.data ?? [],
            has_more: response.has_more,
            total: response.total
        };
    }

    async chart(filter?: FilterChart): Promise<{ [key: string]: number }> {
        const { response } = await this.request(
            'GET',
            this.url('chart'),
            {
                params: {
                    ...filter
                }
            }
        );

        return response.data ?? [];
    }

    create(data: Create): Promise<HttpResponse> {
        return this.request("POST", this.baseURL(), {
            data
        });
    }

    read(id: any): Promise<HttpResponse> {
        return this.request("GET", `${this.baseURL()}/${id}`);
    }

    update(data: Update, id: any): Promise<HttpResponse> {
        return this.request("PUT", `${this.baseURL()}/${id}`, {
            data
        });
    }

    delete(id: any): Promise<HttpResponse> {
        return this.request("DELETE", `${this.baseURL()}/${id}`);
    }
}