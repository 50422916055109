import { SelectionType } from "@/enums";
import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
import { SelectionData, SelectionSave } from "@/models/selection";

class Selection extends BaseResourceHttp<any, SelectionSave, SelectionData> {
    protected baseURL(): string {
        return "selection";
    }

    async all(type: SelectionType): Promise<Array<SelectionData>> {
        const { response } = await this.request("GET", `${this.baseURL()}/all`, {
            params: {
                type
            }
        });

        return response.data ?? [];
    }
}

export default createInstance<Selection>(Selection);