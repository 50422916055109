import { RouteNames } from "@/enums";
import { ChildrenRecordRaw } from "@/lib/router";

export default {
    path: "/company_setting",
    name: RouteNames.CompanySetting,
    component: () => import("@/pages/Admin/CompanySetting.vue"),
    meta: {
        withContentFooter: true
    }
} as ChildrenRecordRaw;