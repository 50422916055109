
import { defineComponent } from "vue";
import { useRouter } from "@/lib/router";
import SideMenu from "@/components/App/SideMenu.vue";
import template from "@/template/app";
import TopMenu from "@/components/App/TopMenu.vue";
import Content from "@/components/App/Content.vue";
import { helperMixin } from "@/mixins";
import { RouteNames } from "@/enums";
import { Menu } from "@/interfaces";

export default defineComponent({
  mixins: [helperMixin],

  components: {
    SideMenu,
    TopMenu,
    Content
  },

  async mounted() {
    this.setPageTitle();

    this.appStore.state.loadingRoute = true;

    await this.appStore.fetchMenus();

    await this.checkUserRole();

    await this.setActiveMenu();

    await this.$nextTick();

    this.appStore.state.loadingRoute = false;

    useRouter().afterEach(() => {
      this.appStore.state.loadingRoute = false;
    });

    template();
  },

  data() {
    return {
      checkingRole: true
    };
  },

  methods: {
    async setActiveMenu() {
      this.appStore.state.activeMenu = this.findActiveMenuByCurrentRoute(
        this.appStore.state.menus
      );

      // await this.$nextTick();

      // if (!this.appStore.state.activeMenu?.permission)
      //   await this.useRouter().push({ name: this.RouteNames.Dashboard });
    },

    findActiveMenuByCurrentRoute(menus: Menu[]) {
      for (let i = 0; i < menus.length; i++) {
        if (
          (menus[i].vue_router_name === this.currentRoute.name ||
            menus[i].vue_router_name === this.currentRoute.meta?.rootName) &&
          !menus[i].menus?.length
        ) {
          return menus[i];
        }

        let foundInChilds = this.findActiveMenuByCurrentRoute(
          (menus[i].menus ?? []) as Menu[]
        );

        if (foundInChilds) return foundInChilds;
      }
    },

    async checkUserRole() {
      this.checkingRole = true;

      if (
        this.currentRoute.meta?.userRole &&
        this.currentRoute.meta?.userRole !== this.authStore.state.user.role
      ) {
        await useRouter().push({
          name: RouteNames.Dashboard
        });
      }

      this.checkingRole = false;
    }
  },

  watch: {
    async currentRoute() {
      this.setActiveMenu();
      if (this.appStore.state.activeMenu) {
        this.appStore.state.activeMenu.breadcrumbs = [];
      }
    }
  }
});
