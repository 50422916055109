import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2.5" }
const _hoisted_2 = { class: "flex items-center h-5" }
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.randId,
        type: "radio",
        class: "form-radio focus:ring-transparent border text-theme",
        value: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
      }, null, 8, _hoisted_3), [
        [_vModelRadio, _ctx.inputValue]
      ])
    ]),
    _createElementVNode("label", {
      for: _ctx.randId,
      class: "capitalize"
    }, _toDisplayString(_ctx.label), 9, _hoisted_4)
  ]))
}