require("pusher-js");
import Echo from 'laravel-echo';
import Http from '@/lib/http';

export default new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: process.env.NODE_ENV === 'production',
    forceTLS: true,

    authorizer: (channel) => {
        return {
            authorize: async (socketId, callback) => {
                (new Http).request("POST", 'broadcasting/auth', {
                    data: {
                        socket_id: socketId,
                        channel_name: channel.name
                    },
                })
                    .then(data => {
                        callback(false, data.response);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    },
});