import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "root js-root" }
const _hoisted_2 = { class: "root-sidebar js-sidebar" }
const _hoisted_3 = {
  key: 0,
  class: "root-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_top_menu = _resolveComponent("top-menu")!
  const _component_content = _resolveComponent("content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_side_menu)
    ]),
    (!_ctx.checkingRole)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_top_menu),
          _createVNode(_component_content)
        ]))
      : _createCommentVNode("", true)
  ]))
}