import Dropdown from "@/components/Dropdown/Dropdown.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import DropdownMenu from "@/components/Dropdown/DropdownMenu.vue";
import DropdownToggler from "@/components/Dropdown/DropdownToggler.vue";
import {
  CrewStatus,
  EventNames,
  JobApplicantInterviewAct,
  JobApplicantRescheduleAct,
  JobApplicantStatus,
  RouteNames,
  UserRole,
} from "@/enums";
import authFacade, { AttemptFormData, AttemptType } from "@/facades/auth";
import {
  capitalize,
  goTo,
  makeFirstCapital,
  numberFormat,
  randomString,
  replaceWithSpace,
  trans,
} from "@/helpers";
import authHttp from "@/http/auth";
import jobApplicantHttp from "@/http/jobApplicant";
import {
  BasePaginateFilter,
  PaginateResponse,
  SelectedSelection,
} from "@/interfaces";
import { createInstance } from "@/lib/fn";
import http, { HttpResponse } from "@/lib/http";
import PubSub from "@/lib/pubSub";
import BaseResourceHttp from "@/lib/resourceHttp";
import { RouteRecordOption, useRouter } from "@/lib/router";
import { CrewBoardPaginate } from "@/models/crewBoard";
import {
  JobApplicantPaginate,
  JobApplicantPaginateFilter,
} from "@/models/jobApplicant";
import { SelectionData } from "@/models/selection";
import appStore from "@/store/app";
import authStore from "@/store/auth";
import contentTool from "@/store/contentTool";
import selectionStore from "@/store/selection";
import {
  Layout,
  offContentBodyScroll,
  onContentBodyScroll,
  toast,
} from "@/template/app";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { DefineComponent, defineComponent, PropType, watch } from "vue";

export const interviewResponse = defineComponent({
  computed: {
    jobApplicantHttp,
    pubSub: createInstance<PubSub>(PubSub),
  },
  data() {
    return {
      JobApplicantInterviewAct,
      selectedJobApplicant: false,
      acceptInterviewModal: false,
      rejectInterviewModal: false,
    };
  },
  methods: {
    rejectInterview(row: any) {
      this.selectedJobApplicant = row;
      this.rejectInterviewModal = true;
    },
    acceptInterview(row: any) {
      this.selectedJobApplicant = row;
      this.acceptInterviewModal = true;
    },
    async requestHandler(
      request: Promise<HttpResponse>
    ): Promise<null | HttpResponse> {
      console.error("request handler method not implement");

      return Promise.reject();
    },
    // ini method di "infinityScroll"
    reload() {
      return console.error("reload method not implement");
    },
    onInterviewResponseSuccess(act: JobApplicantInterviewAct) {
      this.reload();
    },
    /**
     * hire / reject interview berdasarkan data id selectedjobapplicant
     */
    async interviewResponse(id: any, act: JobApplicantInterviewAct) {
      if (
        await this.requestHandler(this.jobApplicantHttp.acceptOrReject(id, act))
      ) {
        this.pubSub.trigger(EventNames.InterviewRespond);
        this.onInterviewResponseSuccess(act);
      }
    },
  },
});

export const rescheduleResponse = defineComponent({
  computed: {
    jobApplicantHttp,
    pubSub: createInstance<PubSub>(PubSub),
  },
  data() {
    return {
      JobApplicantRescheduleAct,
      selectedJobApplicant: null,
      acceptRescheduleModal: false,
      rejectRescheduleModal: false,
    };
  },
  methods: {
    rejectReschedule(row: any) {
      this.selectedJobApplicant = row;
      this.rejectRescheduleModal = true;
    },
    acceptReschedule(row: any) {
      this.selectedJobApplicant = row;
      this.acceptRescheduleModal = true;
    },
    async requestHandler(
      request: Promise<HttpResponse>
    ): Promise<null | HttpResponse> {
      console.error("request handler method not implement");

      return Promise.reject();
    },
    // ini method di "infinityScroll"
    reload() {
      return console.error("reload method not implement");
    },
    onRescheduleResponseSuccess(act: JobApplicantRescheduleAct) {
      this.reload();
    },
    /**
     * accept / reject schedule berdasarkan data id selectedjobapplicant
     */
    async rescheduleResponse(id: any, act: JobApplicantRescheduleAct) {
      if (
        await this.requestHandler(
          this.jobApplicantHttp.rescheduleResponse(id, act)
        )
      ) {
        this.pubSub.trigger(EventNames.RescheduleRespond);
        this.onRescheduleResponseSuccess(act);
      }
    },
  },
});

export const planningModal = defineComponent({
  data() {
    return {
      selectedCrewBoard: null as null | CrewBoardPaginate,
      showRenewCrewSignOffModal: false,
      showSwitchCrewModal: false,
    };
  },
  methods: {
    async renewCrewSignOff(crewBoard: CrewBoardPaginate) {
      this.selectedCrewBoard = crewBoard;
      this.showRenewCrewSignOffModal = true;
    },

    async switchCrew(crewBoard: CrewBoardPaginate) {
      this.selectedCrewBoard = crewBoard;
      this.showSwitchCrewModal = true;
    },
  },
});

export const userProfilePDF = defineComponent({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    pdfFilename() {
      return "untitled";
    },
  },
  methods: {
    async generatePDF() {
      this.loading = true;

      if (this.$refs.pdfContent) {
        await html2pdf()
          .set({
            margin: 10,
            filename: `${this.pdfFilename}.pdf`,
            pagebreak: {
              mode: ["avoid-all", "css", "legacy"],
            },
            html2canvas: {
              dpi: 192,
              useCORS: true,
            },
            jsPDF: {
              orientation: "portrait",
              format: "a4",
            },
          })
          .from(this.$refs.pdfContent)
          .save();
      }

      this.loading = false;
    },
  },
});

export const crewStatus = defineComponent({
  data() {
    return {
      CrewStatus,
    };
  },
  methods: {
    badgeIconClass(status: CrewStatus) {
      if (
        status === CrewStatus.StandBy ||
        status === CrewStatus.StandByOnFleet
      ) {
        return "mdi mdi-calendar-check";
      } else if (status === CrewStatus.OnBoard) {
        return "mdi mdi-calendar-account";
      } else if (status === CrewStatus.Releiver) {
        return "mdi mdi-swap-horizontal";
      }

      return "";
    },

    badgeColor(status: CrewStatus) {
      if (status === CrewStatus.StandBy) {
        return "success";
      } else if (status === CrewStatus.StandByOnFleet) {
        return "warning";
      } else if (status === CrewStatus.OnBoard) {
        return "primary";
      } else if (status === CrewStatus.Releiver) {
        return "warning";
      }

      return "";
    },
  },
});

export const jobApplicantStatus = defineComponent({
  data() {
    return {
      JobApplicantStatus,
    };
  },
  methods: {
    isRejected(status: JobApplicantStatus) {
      return (
        status === JobApplicantStatus.Rejected ||
        status === JobApplicantStatus.RescheduleRejected ||
        status === JobApplicantStatus.InterviewRejected
      );
    },

    isInterview(status: JobApplicantStatus) {
      return (
        status === JobApplicantStatus.Interview ||
        status === JobApplicantStatus.Rescheduled
      );
    },

    badgeIconClass(status: JobApplicantStatus) {
      if (
        status === JobApplicantStatus.InterviewInvitation ||
        status === JobApplicantStatus.Rescheduled
      ) {
        return "mdi mdi-calendar-clock";
      } else if (status === JobApplicantStatus.Interview) {
        return "mdi mdi-calendar-check";
      } else if (status === JobApplicantStatus.RescheduleRequest) {
        return "mdi mdi-account-question";
      } else if (this.isRejected(status)) {
        return "mdi mdi-close";
      } else if (status === JobApplicantStatus.Hired) {
        return "mdi mdi-check-bold";
      } else if (status === JobApplicantStatus.RecruitmentRequest) {
        return "mdi mdi-account-clock";
      }

      return "";
    },

    badgeColor(status: JobApplicantStatus) {
      if (
        status === JobApplicantStatus.Interview ||
        status === JobApplicantStatus.InterviewInvitation
      ) {
        return "primary";
      } else if (status === JobApplicantStatus.Rescheduled) {
        return "info";
      } else if (
        status === JobApplicantStatus.RescheduleRequest ||
        status === JobApplicantStatus.RecruitmentRequest
      ) {
        return "warning";
      } else if (this.isRejected(status)) {
        return "danger";
      } else if (status === JobApplicantStatus.Hired) {
        return "success";
      }

      return "";
    },
  },
});

export const selectionSaveModal = defineComponent({
  computed: {
    selectionStore,
  },

  data() {
    return {
      selectionSaveModalTitle: "",
      selectedSelection: {} as SelectedSelection,
      showSelectionSaveModal: false,

      showConfirmDeleteSelectionModal: false,
    };
  },

  methods: {
    async removeSelectionConfirmed() {
      if (
        this.selectedSelection.type &&
        (await this.selectionStore.remove(
          this.selectedSelection.type,
          this.selectedSelection.id,
          this.selectedSelection.parentId
        ))
      ) {
        this.showConfirmDeleteSelectionModal = false;
      }
    },
    removeSelection(selection: SelectedSelection) {
      this.selectedSelection = selection;
      this.showConfirmDeleteSelectionModal = true;
    },
    addOrEditSelection(
      title: string,
      selectedSelection: SelectedSelection | null = null
    ) {
      if (selectedSelection) {
        this.selectedSelection = selectedSelection;
      }
      this.selectionSaveModalTitle = title;
      this.showSelectionSaveModal = true;
    },
  },
});

export const positionFilter = <T>(filterKey: string = "position_in") =>
  defineComponent({
    data() {
      return {
        submittedFilter: {} as unknown as T,
        filter: {} as unknown as T,
        formGroupPosition: null as unknown as DefineComponent,
      };
    },
    computed: {
      selectionStore,
    },
    mounted() {
      this.selectionStore.fetch();
      this.formGroupPosition = this.$refs.formGroupPosition as any;
    },
    methods: {
      async resetFilterPositions() {
        this.filter[filterKey] = [];

        for (
          let i = 0;
          i != (this.submittedFilter[filterKey]?.length ?? 0);
          i++
        ) {
          const position = this.selectionStore.findById(
            this.submittedFilter[filterKey]?.[i],
            this.selectionStore.state.job_position
          );

          if (position) {
            this.filter[filterKey].push({
              value: position.id,
              text: position.name,
            });
          }
        }

        await this.$nextTick();

        this.formGroupPosition.$refs.select.initSelectedItem();
      },
    },
  });

export const filterHelper = <T>() =>
  defineComponent({
    data() {
      return {
        submittedFilter: {} as unknown as T,
        filter: {} as unknown as T,
      };
    },
    computed: {
      exceptKeysForFilterCount() {
        return ["search", "page"];
      },
    },
    methods: {
      resetFilterPositions() {
        return;
      },
      reload() {
        return;
      },
      onReset() {
        return;
      },
      compareFilterWithSubmitted() {
        const keys = Object.keys(this.filter as any);

        for (let i = 0; i != keys.length; i++) {
          if (this.exceptKeysForFilterCount.indexOf(keys[i]) === -1) {
            this.filter[keys[i]] = this.submittedFilter[keys[i]];
          }
        }
      },
      async resetAll(reload = false) {
        await this.$nextTick();

        this.compareFilterWithSubmitted();
        this.resetFilterPositions();
        this.onReset();

        if (reload) {
          this.reload();
        }
      },
    },
  });

export const syncQueryAndData = defineComponent({
  methods: {
    /**
     * panggil saat mounted.
     */
    syncQueryAndData(
      data: Function,
      queryName: string,
      initDataValue: (queryValue: string) => void,
      setQueryValueOnChange: ((dataValue: any) => string) | null = null
    ) {
      if (initDataValue)
        initDataValue((this.$route.query[queryName] ?? "") as string);

      // memberi nilai query string dari nilai data
      watch(
        () => data(),
        (val) => {
          const query = {
            ...this.$route.query,
          };

          if (setQueryValueOnChange) {
            query[queryName] = setQueryValueOnChange(val);
          } else {
            query[queryName] = val;
          }

          this.$router.replace({
            query,
          });
        },
        {
          deep: true,
        }
      );
    },
  },
});

export const selectionDataHelper = defineComponent({
  methods: {
    compareSelectionOrder(a: SelectionData, b: SelectionData) {
      return b.id - a.id;
    },
  },
});

export const selectionDataProp = defineComponent({
  mixins: [selectionDataHelper],
  props: {
    selectionData: {
      type: Object as PropType<import("@/models/selection").SelectionData>,
    },
  },
  computed: {
    childs() {
      if (!this.selectionData?.recursive_childs) return [];

      return this.selectionData?.recursive_childs.sort(
        this.compareSelectionOrder
      );
    },
  },
});

export const baseModal = defineComponent({
  emits: ["update:modelValue"],
  props: {
    title: String,
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: false,
    };
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.value = val;
      },
    },

    value(val) {
      this.$emit("update:modelValue", val);
    },
  },
});

export const dropdown = defineComponent({
  components: { DropdownItem, Dropdown, DropdownToggler, DropdownMenu },
});

export const actionToolDropdown = defineComponent({
  emits: ["edit", "delete"],
  props: {
    withEdit: {
      type: Boolean,
      default: true,
    },
    withDelete: {
      type: Boolean,
      default: true,
    },
    menuAlign: {
      type: String as PropType<"left" | "right">,
      default: "right",
    },
    editRoute: {
      type: Object as PropType<import("@/lib/router").GoTo>,
      default: null,
    },
  },
});

export const uwuLoaderDelay = defineComponent({
  props: {
    loading: {
      type: Boolean,
    },
  },
  emits: ["update:loading"],
  computed: {
    uwuLoaderTimeout: () => 700,
  },
  data() {
    return {
      isLoading: false,
      loadingTimeout: 0,
    };
  },
  watch: {
    loading: {
      immediate: true,
      handler(isLoading) {
        if (!isLoading && this.isLoading) {
          this.$emit("update:loading", true);

          clearTimeout(this.loadingTimeout);

          this.loadingTimeout = setTimeout(() => {
            this.isLoading = false;
            this.$emit("update:loading", false);
          }, this.uwuLoaderTimeout);

          return;
        }

        this.isLoading = isLoading;
      },
    },
  },
});

export const groupInput = defineComponent({
  props: {
    label: {
      type: String,
    },
    value: {
      required: true,
    },
  },
  computed: {
    randId() {
      return randomString();
    },
  },
});

export const twoStateBindValue = defineComponent({
  props: ["modelValue"],
  emits: ["update:modelValue", "updated"],
  data() {
    return {
      inputValue: "",
    };
  },
  watch: {
    inputValue: {
      deep: true,
      handler(val) {
        this.$emit("update:modelValue", val);
        this.$emit("updated", val);
      },
    },
    modelValue: {
      deep: true,
      immediate: true,
      handler(val) {
        this.inputValue = val;
        this.$emit("updated", val);
      },
    },
  },
});

export const saveAndUpdate = <T, K>(
  resourceHttp: BaseResourceHttp<any, any, any>
) =>
  defineComponent({
    mixins: [helperMixin],
    emits: ["saved"],
    computed: {
      updateId() {
        return this.currentRoute.params?.id;
      },
      isUpdateState(): boolean {
        return this.updateId ? true : false;
      },
      redirectAfterSubmit(): boolean {
        return true;
      },
    },
    data() {
      return {
        loading: true,
        /** data from response "read" */
        data: null as unknown as K,
        form: {} as unknown as T,
        validationErrors: {} as any,
        submitting: false,
      };
    },
    async mounted() {
      await this.setup();
    },
    methods: {
      async setup() {
        this.loading = true;
        this.data = null as any;

        if (this.isUpdateState) {
          const { response, status } = await resourceHttp.read(this.updateId);

          if (status === 200) this.data = response.data;
        }

        await this.onMounted();

        await this.$nextTick();

        this.loading = false;

        this.setForm();
      },

      afterSubmit(data) {
        if (process.env.mode !== "production" && !this.redirectAfterSubmit) {
          console.warn("method not implemented");
        }
      },

      async onMounted() {
        if (process.env.mode !== "production") {
          console.warn("method not implemented");
        }
      },

      setForm() {
        if (process.env.mode !== "production") {
          console.warn("method not implemented");
        }
      },

      async submit() {
        if (this.submitting) return;

        this.validationErrors = {};

        try {
          this.submitting = true;

          let http: HttpResponse;

          if (this.isUpdateState) {
            http = await resourceHttp.update(this.form, this.updateId);
          } else {
            http = await resourceHttp.create(this.form);
          }

          if (http.status === 200) {
            if (this.redirectAfterSubmit) {
              useRouter().push({
                name: this.currentRoute.meta?.rootName,
              });
            } else {
              this.afterSubmit(http.response.data);
            }

            toast("success", this.makeFirstCapital(http.message));

            this.$emit("saved", http.response.data);
          } else if (http.status !== 422) {
            toast("error", http.message, 0);
          }

          this.submitting = false;
        } catch (e: any) {
          this.submitting = false;
          // entar dibuat agar ditampung di variable global
          // sehingga menampilkan halaman error
          toast("error", e.message, 0);
          throw e;
        }

        this.validationErrors = resourceHttp.validationErrors;
      },
    },
  });

/**
 * Menghandle infinity scroll. setelah implement mixin ini,
 * method "mounted" better jangan di override, gunakan "onMounted" untuk penggantinya.
 *
 *
 * @param {BaseResourceHttp} resourceHttp
 * @param {BasePaginateFilter} filter
 * @return
 */
export const infinityScrollTable = <
  T,
  K extends BasePaginateFilter,
  R extends PaginateResponse<T[]> = PaginateResponse<T[]>
>(
  resourceHttp: BaseResourceHttp<any, any, any>,
  filter?: Omit<K, "search" | "page" | "limit">
) =>
  defineComponent({
    computed: {
      contentTool,
      resourceHttp: () => resourceHttp,
      noDataAvailable() {
        return !this.loading && !this.rows.length;
      },
      noResultFound() {
        return !this.rows.length && this.filter.search !== "";
      },
      paginateMethodName() {
        return "paginate";
      },
    },

    data: () => ({
      selectedRow: null as null | T | any,
      confirmDeleteModal: false,
      searchInputTimeout: 0,
      spaceBeforeLoadMore: 10,
      loading: false,
      hasMore: true,
      filter: {
        ...filter,
        search: "",
        page: 1,
      } as K,
      rows: [] as Array<T>,
      totalAllRows: 0,
      bodyScrollEventIndex: -1,
      withContentBodyScrollEvent: true,
      unwatchSearch: () => {},
    }),

    async mounted() {
      await this.onMounted();

      this.fetch();

      if (this.withContentBodyScrollEvent) {
        this.bodyScrollEventIndex = onContentBodyScroll((e: Event) =>
          this.handleScroll(e.target as HTMLElement)
        );
      }

      this.unwatchSearch = this.contentTool.watch("search", (val) => {
        clearTimeout(this.searchInputTimeout);
        this.searchInputTimeout = setTimeout(() => {
          this.filter.search = val;
          this.hasMore = true;
          this.filter.page = 1;
          this.fetch(true);
        }, 300);
      });

      useRouter().beforeResolve(() => {
        offContentBodyScroll(this.bodyScrollEventIndex);
        this.unwatchSearch();
      });
    },

    methods: {
      async onMounted() {},
      async deleteSelectedRow() {
        if (!this.selectedRow) return;

        const { status, message } = await resourceHttp.delete(
          this.selectedRow.id as any
        );

        if (status === 200) {
          this.confirmDeleteModal = false;
          this.selectedRow = null;
          this.rows = [];
          this.filter.page = 1;
          this.fetch();
        } else {
          toast("error", message, 0);
        }
      },
      showConfirmDelete(d: any) {
        this.confirmDeleteModal = true;
        this.selectedRow = d;
      },
      loadMore() {
        if (!this.hasMore || this.loading) return;

        this.filter.page++;
        this.fetch();
      },
      handleScroll(contentBody: HTMLElement) {
        if (
          contentBody.scrollTop >=
          contentBody.scrollHeight -
            contentBody.clientHeight -
            this.spaceBeforeLoadMore
        ) {
          this.loadMore();
        }
      },
      onFetchSuccess(data: R) {
        return;
      },
      async fetch(reset: boolean = false) {
        if (this.loading) return;

        this.loading = true;
        this.filter.search = this.contentTool.state.search;

        const res = await this.resourceHttp[this.paginateMethodName](
          this.filter
        );

        this.onFetchSuccess(res as unknown as R);

        const { data, has_more, total, status, message } = res;

        this.rows = !reset
          ? [...this.rows, ...((data ?? []) as Array<any>)]
          : data || [];
        this.hasMore = has_more;
        this.totalAllRows = total | 0;
        this.loading = false;

        if (status !== 200) {
          toast("error", message ?? trans("layout.error"), 0);
        }
      },
      async reload() {
        Layout()?.contentBody?.scrollTo({
          top: 0,
        });

        this.filter.page = 1;
        this.fetch(true);
      },
    },
  });

export const infinitySrollJobApplicantByStatus = (
  status_in: JobApplicantStatus[]
) =>
  defineComponent({
    mixins: [
      infinityScrollTable<JobApplicantPaginate, JobApplicantPaginateFilter>(
        jobApplicantHttp()
      ),
    ],
    beforeMount() {
      this.filter.status_in = status_in;
    },
  });

export const authMixin = <T extends AttemptType>(type?: T) =>
  defineComponent({
    data() {
      return {
        errorMsg: "",
        loading: false,
        formData: {} as AttemptFormData<T>,
        validationErrors: {},
      };
    },
    computed: {
      authHttp,
      authFacade,
      authStore,
    },
    methods: {
      async submit() {
        if (type === undefined) return;

        this.loading = true;

        const { failed, message, validationErrors } =
          await this.authFacade.attempt(
            type,
            this.formData as AttemptFormData<T>
          );

        if (failed) {
          this.errorMsg = message;
        }

        this.loading = false;
        this.validationErrors = validationErrors || {};
      },
    },
  });

export const helperMixin = defineComponent({
  data() {
    return {
      UserRole,
      RouteNames,
      EventNames,
      requestHandlerLoading: false,
      requestHandlerStatus: undefined as undefined | number,
      validationErrors: {} as any,
    };
  },
  computed: {
    appStore,
    authStore,
    pubSub: createInstance<PubSub>(PubSub),
    http: createInstance<http>(http),
    currentRoute(): RouteRecordOption {
      return this.$route as unknown as RouteRecordOption;
    },
  },
  methods: {
    useRouter,
    goTo,
    trans,
    numberFormat,
    makeFirstCapital,
    capitalize,
    replaceWithSpace,
    setPageTitle(title?: string) {
      document.title = title
        ? title + " | " + process.env.VUE_APP_NAME
        : process.env.VUE_APP_NAME;
    },
    yearKeyup(e: any) {
      const value: string = (e.target.value = e.target.value.replace(
        /[^0-9]/g,
        ""
      ));

      if (value.length > String(new Date().getFullYear()).length) {
        e.target.value = value.substring(0, 4);
      }
    },
    formatNotificationCount(count: number) {
      return count > 99 ? "99+" : count;
    },
    emptyString(val: string) {
      return val ? val : "-";
    },
    formatDate(val: string, withTime = false) {
      if (!val) return "";

      const padWithZero = (value: number) => {
        return value < 10 ? "0" + value : value;
      };

      try {
        val.split("T");
      } catch (error) {
        return val;
      }

      const valsplit = val.split("T");

      const arr = (
        valsplit[0] + (valsplit[1] ? " " + valsplit[1]?.substr(0, 5) : "")
      ).split(/[- :]/) as any;

      const date = new Date(
        arr[0],
        arr[1] - 1,
        arr[2],
        arr[3] ?? null,
        arr[4] ?? null,
        arr[5] ?? null
      );

      const year = date.getFullYear();
      const month = padWithZero(date.getMonth() + 1);
      const day = padWithZero(date.getDate());
      const hours = padWithZero(date.getHours());
      const minutes = padWithZero(date.getMinutes());
      const seconds = padWithZero(date.getSeconds());

      if (date.toString() === "Invalid Date") {
        return val;
      }

      return (
        day +
        "-" +
        month +
        "-" +
        year +
        (withTime ? " " + hours + ":" + minutes : "")
      );
    },
    async requestHandler(
      request: Promise<HttpResponse>
    ): Promise<null | HttpResponse> {
      if (this.requestHandlerLoading) return null;

      this.requestHandlerLoading = true;

      const res = await request;

      this.requestHandlerLoading = false;

      this.validationErrors = res.validationErrors || {};

      this.requestHandlerStatus = res.status;

      if (res.status !== 200 && res.status !== 422) {
        toast("error", res.message, 0);
      } else if (res.status === 200) {
        toast("success", res.message);

        return res;
      }

      return null;
    },
    imageUrlFromFile(file: File) {
      return URL.createObjectURL(file);
    },
    selectionDataToBaseItem(
      arrs: SelectionData[],
      otherDataKeys: null | any[] = null
    ) {
      if (!arrs.map) return [];

      const items = [] as any;

      for (let i = 0; i < arrs.length; i++) {
        const item = {
          value: arrs[i].id,
          text: arrs[i].name,
        };

        if (otherDataKeys) {
          for (let j = 0; j < otherDataKeys.length; j++) {
            item[otherDataKeys[j]] = arrs[i][otherDataKeys[j]];
          }
        }

        items.push(item);
      }

      return items;
    },
    getAge(date: string) {
      return moment().diff(date, "years", false);
    },
  },
});
