import { EventNames } from "@/enums";

interface PubSubCallback {
    (val?: any): any;
}

type Event = {
    [K in EventNames]: Array<PubSubCallback>
};

export default class PubSub {
    protected events: Event = {} as Event;

    on(eventName: EventNames, callback: PubSubCallback) {
        this.events[eventName] = this.events[eventName] || [];

        const index = this.events[eventName].push(callback) - 1;

        return () => {
            this.events[eventName] = index != 0 ? this.events[eventName].slice(index, 1) : [];
        };
    }

    trigger(eventName: EventNames, val?: any) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(callback => callback(val));
        }
    }
}