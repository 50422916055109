
import { defineComponent, ref } from "vue";
import TopMenuDropdown from "@/components/App/TopMenuDropdown.vue";
import { helperMixin, infinityScrollTable, planningModal } from "@/mixins";
import CompanyLogo from "@/components/CompanyLogo.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import echo from "@/lib/echo";
import AvatarImg from "@/components/AvatarImg.vue";
import SwitchCrewModal from "@/components/Planning/SwitchCrewModal.vue";
import RenewCrewSignoffModal from "@/components/Planning/RenewCrewSignoffModal.vue";
import crewBoardHttp from "@/http/crewBoard";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { CrewBoardPaginate, CrewBoardPaginateFilter } from "@/models/crewBoard";
import CustomNotification from "@/lib/customNotification";

export default defineComponent({
  mixins: [
    helperMixin,
    planningModal,
    infinityScrollTable<CrewBoardPaginate, CrewBoardPaginateFilter>(
      crewBoardHttp(),
      {
        for_reminder: 1
      }
    )
  ],

  components: {
    BaseButtonPrimary,
    TopMenuDropdown,
    CompanyLogo,
    BaseLink,
    AvatarImg,
    SwitchCrewModal,
    RenewCrewSignoffModal
  },

  computed: {
    crewBoardHttp
  },

  setup() {
    return {
      toggler: (ref(null) as unknown) as HTMLButtonElement
    };
  },

  data() {
    return {
      withContentBodyScrollEvent: false,
      showSwitchCrewModal: false,
      showRenewCrewSignOffModal: false
    };
  },

  mounted() {
    this.listenCrewBoardReminderEvent();
  },

  beforeUnmount() {
      echo.leave("crewboard-reminder");
    },

  methods: {
    listenCrewBoardReminderEvent() {
      const channel = echo
        .private("crewboard-reminder")
        .listen(".minutely", async () => {
          await this.reload();

          new CustomNotification(
            this.makeFirstCapital(this.trans("layout.planning_reminder")), {
            body: this.trans("layout.planning_reminder_notif_msg", {
              total: this.totalAllRows
            })
          }).addEventListener("click", () => {
            this.toggler.click();
          });

          channel.stopListening(".minutely");
        });
    }
  }
});
