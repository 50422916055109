
import TopMenuDropdown from "@/components/App/TopMenuDropdown.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import NavItemPlanningReminder from "@/components/App/NavItemPlanningReminder.vue";
import NavItemNotification from "@/components/App/NavItemNotification.vue";
import NavItemRescheduleRequest from "@/components/App/NavItemRescheduleRequest.vue";
import auth from "@/http/auth";

export default defineComponent({
  mixins: [helperMixin],

  components: {
    TopMenuDropdown,
    CompanyLogo,
    BaseLink,
    AvatarImg,
    NavItemPlanningReminder,
    NavItemNotification,
    NavItemRescheduleRequest
  },

  methods: {
    async logout() {
      try {
        const { status } = await auth().logout();

        if (status === 200) {
          this.authStore.revokeToken();
        }
      } catch (error) {}
    }
  }
});
