import auth from '@/store/auth';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosTransformer, Method } from 'axios';

export interface HttpResponse {
    readonly status: number | undefined;
    readonly message: string;
    readonly response: any;
    readonly headers: any;
    readonly validationErrors?: ValidationErrors<any>;
}

export type Error = null | {
    message: string;
    code: undefined | number;
};

export type ValidationErrors<T extends string | number | symbol> = {
    [key in T]: []
};

export default class Http {
    private http: AxiosInstance;
    private formData: FormData;

    public validationErrors: ValidationErrors<any> = <any>{};

    public constructor() {
        this.http = axios.create({
            baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API_URL_PROD : process.env.VUE_APP_BASE_API_URL_DEV,
            transformRequest: this.onTransformRequest,
        });

        this.formData = new FormData();
    }

    protected onTransformRequest: AxiosTransformer = (data) => {
        for (let key in data) {
            this.appendFormData(key, data[key] ?? "");
        }

        return this.formData;
    }

    protected appendFormData(name: string, value: any) {
        // value is a object and not a file ?
        if (typeof value === "object" && !(value instanceof File)) {
            // cuz array is read as object
            // if is array and is empty
            if (Array.isArray(value) && !value.length) {
                value = "";
            } else {
                // recursion until value not an object
                for (let key in value) {
                    // recursion...
                    // ex:
                    // parentName[theKey1] recursion 1
                    // parentName[theKey1][theKey2] recursion 2
                    // value of theKey2 is not an object ? it will stop.
                    this.appendFormData(`${name}[${key}]`, value[key]);
                }

                return;
            }
        }

        if (typeof value === "boolean") {
            value = value ? 1 : 0;
        }

        this.formData.append(
            name, value
        );
    }

    protected clearFormData() {
        this.validationErrors = <any>{};
        this.formData = new FormData;
    }

    public request(method: Method, url: string, option?: Omit<AxiosRequestConfig, "method" | "url">): Promise<HttpResponse> {
        this.clearFormData();

        const data = { ...option?.data };

        if (data) {
            // add laravel spoof
            if (String(method).toLocaleLowerCase() === 'put') {
                method = "post";
                data._method = 'put';
            } else if (String(method).toLocaleLowerCase() === 'delete') {
                method = "post";
                data._method = 'delete';
            }
        }

        return new Promise(async (resolve, reject) => {
            this.http.request({
                ...option,
                method,
                url,
                params: option?.params,
                data,
                headers: {
                    'Authorization': auth().getAuthrizationHeader(),
                    ...option?.headers
                }
            })
                .then(({ data, status, headers }) => {
                    resolve({
                        status,
                        message: data.message ?? "",
                        response: data,
                        headers
                    });
                })

                .catch((e: AxiosError) => {

                    if (e.response) {
                        if (e.response.status === 422) {
                            this.validationErrors = e.response.data?.errors;
                        }
                    } else {
                        reject(e.toJSON());
                    }

                    resolve({
                        status: e.response?.status,
                        message: e.response?.data.message ?? "",
                        response: e.response?.data,
                        headers: e.response?.headers,
                        validationErrors: this.validationErrors
                    });
                });
        });
    }
}