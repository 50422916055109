import { RouteNames } from "@/enums";
import { ChildrenRecordRaw } from "@/lib/router";

export default {
    path: "/",
    name: RouteNames.Dashboard,
    component: () => import("@/pages/Home.vue"),
    meta: {
        withContentTools: false,
        rootName: RouteNames.Dashboard
    }
} as ChildrenRecordRaw;