import Store from "@/lib/store";
import { createInstance } from "@/lib/fn";
import app from "@/http/app";
import { ConfigTranslations, Menu } from "@/interfaces";

interface State extends ConfigTranslations {
    menus: Menu[];
    activeMenu: null | Menu;
    loadingRoute: boolean;
    currKey: 1 | 0;
    error: {
        isTrue: boolean;
        code: number;
        message: string;
    };
}

class App extends Store<State> {
    protected states(): State {
        return {
            currKey: 0,
            loadingRoute: false,
            menus: [],
            activeMenu: null,
            lang: "en",
            translations: null,
            error: {
                isTrue: false,
                code: 404,
                message: "Page not found"
            },
        };
    }

    constructor() {
        super();

        window.addEventListener('keydown', () => {
            this.state.currKey = 1;
        });

        window.addEventListener('keyup', () => {
            this.state.currKey = 0;
        });
    }

    public setError(msg: string, code: number) {
        this.state.error.isTrue = true;
        this.state.error.message = msg;
        this.state.error.code = code;
    }

    public async fetchConfig() {
        const { lang, translations } = await app().getTranslations();

        this.state.lang = lang;
        this.state.translations = translations;
    }

    public async fetchMenus() {
        this.state.menus = await app().menus();
    }
}

export default createInstance<App>(App);