
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    onclick(e) {
      window.onclick ? window.onclick(e) : "";
    }
  }
})
