
import { helperMixin } from "@/mixins";
import { UserCertificate } from "@/models/jobApplicant";
import { toast } from "@/template/app";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  mixins: [helperMixin],
  props: {
    certificate: {
      type: Object as PropType<import("@/models/jobApplicant").UserCertificate>,
      default: null,
    },
    certificates: {
      type: Array as PropType<
        import("@/models/jobApplicant").UserCertificate[]
      >,
      default: () => [],
    },
  },
  methods: {
    async downloadCertificate(certificate: UserCertificate) {
      try {
        const { message, response, status, headers } = await this.http.request(
          "GET",
          certificate.download_link,
          {
            responseType: "arraybuffer",
          }
        );

        if (status === 404) {
          throw "FILE NOT FOUND";
        } else if (status === 500) {
          throw "INTERNAL SERVER ERROR";
        }

        const blob = new Blob([response], { type: headers["content-type"] });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = certificate.requirement.name;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } catch (error: any) {
        toast("error", error, 0);
      }
    },
  },
});
