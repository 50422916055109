import { SelectionType } from "@/enums";
import { createInstance } from "@/lib/fn";
import Store from "@/lib/store";
import { SelectionData } from "@/models/selection";
import selectionHttp from "@/http/selection";
import selection from "@/http/selection";
import { toast } from "@/template/app";

type Selections = {
    [key in SelectionType]: Array<SelectionData>;
};

interface State extends Selections {
}

class Selection extends Store<State> {
    protected states(): State {
        return {
            [SelectionType.JobHiringNationality]: [],
            [SelectionType.JobPurpose]: [],
            [SelectionType.JobRequirement]: [],
            [SelectionType.JobPosition]: [],
            [SelectionType.JobContractType]: [],
            [SelectionType.JobSalaryType]: [],
            [SelectionType.JobBenefit]: [],
            [SelectionType.Currency]: [],
            [SelectionType.FleetComunication]: [],
            [SelectionType.FleetComunicationDetail]: [],
        };
    }

    constructor() {
        super();

        this.haventFetched = Object.keys(this.state)
            .filter(key =>
                Object.values(SelectionType)
                    .indexOf(key as SelectionType) !== -1) as Array<SelectionType>;


    }

    // keep data until browser is refresh
    protected haventFetched: Array<SelectionType> = [];

    getTypes() {
        return SelectionType;
    }

    async fetch(): Promise<boolean> {
        const haventFetched = [...this.haventFetched];

        this.haventFetched = [];

        for (let i = 0; i < haventFetched.length; i++) {
            this.state[haventFetched[i]] = await selectionHttp().all(
                haventFetched[i]
            );
        }

        return true;
    }

    findById(id: any, collections: Array<SelectionData>): undefined | SelectionData {
        if (!collections) return;

        for (let i = 0; i < collections.length; i++) {
            if (collections[i].id === id) {
                return collections[i];
            }

            let found = this.findById(id, collections[i].recursive_childs as Array<SelectionData>);

            if (found) return found;
        }
    }

    async remove(type: SelectionType, id: any, parentId?: any): Promise<boolean> {
        let parent: Array<SelectionData>;

        const { status, message } = await selection().delete(id);

        if (status == 200) {
            if (!parentId) {
                parent = this.state[type];
            } else {
                parent = this.findById(parentId, this.state[type])?.recursive_childs as Array<SelectionData>;
            }

            if (Array.isArray(parent)) {
                parent.splice(
                    parent.findIndex(
                        d => d.id === id
                    ),
                    1
                );
            }

            return true;
        }

        toast('error', message, 0);

        return false;
    }
}

export default createInstance<Selection>(Selection);