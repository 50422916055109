import { reactive, watch, WatchCallback, WatchOptions } from "vue";

export default abstract class Store<S extends object> {
    protected _state: any;

    protected abstract states(): S;

    constructor() {
        this._state = reactive({
            ...this.states()
        });
    }

    watch<K extends keyof S>(s: K, cb: WatchCallback, options?: WatchOptions) {
        return watch(() => this._state[s], cb, options);
    }

    get state(): S {
        return this._state;
    }
}