import { LocalStorageKeys } from "@/enums";

/**
 * LocalStorage wrapper
 */
export default class LocalStorage<T extends LocalStorageKeys> {
    private static instance?: LocalStorage<LocalStorageKeys>;

    public static getInstance() {
        if (!this.instance) {
            this.instance = new LocalStorage<LocalStorageKeys>();
        }

        return this.instance;
    }

    public set(key: T, value: string): void {
        return localStorage.setItem(key, value);
    }

    public get(key: T): string | null {
        return localStorage.getItem(key);
    }

    public remove(key: T): void {
        return localStorage.removeItem(key);
    }

    public addEventListener(key: T, callback: (val: string | null) => void) {
        window.addEventListener("storage", () => {
            callback(localStorage.getItem(key));
        });
    }
}