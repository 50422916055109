import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "uwu-nav-menu" }
const _hoisted_2 = { class: "uwu-nav-menu-dialog" }
const _hoisted_3 = { class: "uwu-nav-menu-container" }
const _hoisted_4 = {
  key: 0,
  class: "uwu-nav-menu-header flex justify-between"
}
const _hoisted_5 = { class: "uwu-nav-menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
              _renderSlot(_ctx.$slots, "header")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "uwu-nav-menu-body",
          onScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menu-scroll', $event)))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 32)
      ])
    ])
  ]))
}