import { ConfigTranslations, Menu } from "@/interfaces";
import { createInstance } from "@/lib/fn";
import Http from "@/lib/http";

class App extends Http {
    async getTranslations(): Promise<ConfigTranslations> {
        const { response } = await this.request("GET", "translations");

        return response.data ?? {
            lang: 'en',
            translations: {}
        };
    }

    async menus(): Promise<Menu[]> {
        const { response } = await this.request('GET', "menus");

        return response.data ?? [];
    }
}

export default createInstance<App>(App);