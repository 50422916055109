
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import {
  baseModal,
  helperMixin,
  interviewResponse,
  jobApplicantStatus,
  rescheduleResponse,
  userProfilePDF,
} from "@/mixins";
import { defineComponent, PropType, ref } from "vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import InterviewModal from "@/components/JobApplicant/InterviewModal.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import {
  JobApplicantInterviewAct,
  JobApplicantRescheduleAct,
  JobApplicantStatus,
} from "@/enums";
import CertificateDetail from "@/components/Crew/CertificateDetail.vue";

export default defineComponent({
  components: {
    BaseModal,
    BaseFormGroup,
    BaseButtonDanger,
    BaseButtonPrimary,
    AvatarImg,
    BaseBadge,
    InterviewModal,
    BaseButtonDefault,
    ConfirmModal,
    CertificateDetail,
  },

  mixins: [
    interviewResponse,
    rescheduleResponse,
    helperMixin,
    jobApplicantStatus,
    baseModal,
    userProfilePDF,
  ],

  emits: [
    "update:modelValue",

    "interview-created",
    "rejected",

    "reschedule-accepted",
    "reschedule-refused",

    "interview-hired",
    "interview-rejected",
  ],

  props: {
    jobApplicant: {
      type: Object as PropType<
        import("@/models/jobApplicant").JobApplicantPaginate
      >,
      required: false,
    },
  },

  data() {
    return {
      JobApplicantRescheduleAct,
      JobApplicantStatus,
      confirmDeleteModal: false,
      showInterviewModal: false,
    };
  },

  computed: {
    pdfFilename() {
      return this.jobApplicant?.user.fullname;
    },
  },

  methods: {
    onInterviewResponseSuccess(act: JobApplicantRescheduleAct) {
      if (act === JobApplicantRescheduleAct.Accept) {
        this.$emit("interview-hired");
      } else if (act === JobApplicantRescheduleAct.Reject) {
        this.$emit("interview-rejected");
      }

      this.$emit("update:modelValue", false);
    },

    async interviewResponseHandler(act: JobApplicantInterviewAct) {
      if (!this.jobApplicant) return;

      this.loading = true;

      await this.interviewResponse(this.jobApplicant.id, act);

      this.loading = false;
    },

    onRescheduleResponseSuccess(act: JobApplicantRescheduleAct) {
      if (act === JobApplicantRescheduleAct.Accept) {
        this.$emit("reschedule-accepted");
      } else if (act === JobApplicantRescheduleAct.Reject) {
        this.$emit("reschedule-refused");
      }

      this.$emit("update:modelValue", false);
    },

    async rescheduleResponseHandler(act: JobApplicantRescheduleAct) {
      if (!this.jobApplicant) return;

      this.loading = true;

      await this.rescheduleResponse(this.jobApplicant.id, act);

      this.loading = false;
    },

    interviewCreated() {
      this.$emit("update:modelValue", false);
      this.$emit("interview-created");
    },

    async reject() {
      if (!this.jobApplicant) return;

      this.loading = true;

      if (
        await this.requestHandler(
          this.jobApplicantHttp.rejects([this.jobApplicant.id])
        )
      ) {
        this.$emit("update:modelValue", false);
        this.$emit("rejected");
      }

      this.loading = false;
    },

    async downloadCertificate(certificate: UserCertificate) {
      try {
        const { message, response, status, headers } = await this.http.request(
          "GET",
          certificate.download_link,
          {
            responseType: "arraybuffer",
          }
        );

        if (status === 404) {
          throw "FILE NOT FOUND";
        } else if (status === 500) {
          throw "INTERNAL SERVER ERROR";
        }

        const blob = new Blob([response], { type: headers["content-type"] });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = certificate.requirement.name;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } catch (error: any) {
        toast("error", error, 0);
      }
    },
  },
});
