
import BaseInput from "@/components/Base/BaseInput.vue";
import { EventNames } from "@/enums";
import { BaseItem, BasePaginateFilter } from "@/interfaces";
import { helperMixin } from "@/mixins";
import { toast } from "@/template/app";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  mixins: [helperMixin],
  emits: ["update:modelValue", "change", "add-button-click"],
  components: { BaseInput },
  props: {
    withAddButton: {
      type: Boolean,
      default: false,
    },

    selectClass: String,

    removedSelectedItem: {
      type: Function as PropType<
        (item: import("@/interfaces").BaseItem) => boolean
      >,
      required: false,
      default: undefined,
    },

    keyValue: {
      type: String,
      default: "value",
    },

    keyText: {
      type: String,
      default: "text",
    },

    filterPaginate: {
      type: Object as PropType<
        Omit<import("@/interfaces").BasePaginateFilter, "search" | "page">
      >,
    },

    resourceHttp: {
      type: Object as PropType<
        import("@/lib/resourceHttp").default<any, any, any>
      >,
      default: null,
    },

    modelValue: {
      required: true,
    },

    items: {
      type: Array as PropType<import("@/interfaces").BaseItem[]>,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    filteredOptions() {
      if (this.search && !this.resourceHttp) {
        return this.options.filter((item) =>
          String(item[this.keyText])
            .toLowerCase()
            .match(this.search.toLowerCase())
        );
      }

      return this.options;
    },
  },

  data() {
    return {
      columns: [],
      selectedItem: undefined as undefined | BaseItem | BaseItem[],

      search: "",
      page: 0,

      loadMore: true,
      onRequest: false,

      showOption: false,
      options: [] as Array<BaseItem>,

      offBaseSelectClickEvent: () => {},
      searchTimeoutFn: 0,
    };
  },

  async mounted() {
    this.offBaseSelectClickEvent = this.pubSub.on(
      this.EventNames.BaseSelectClick,
      (val) => {
        if (val == this) return;

        this.showOption = false;
      }
    );

    this.options = this.items;

    window.addEventListener("click", this.onWindowClick);

    await this.$nextTick();

    this.initSelectedItem();
  },

  beforeUnmount() {
    window.removeEventListener("click", this.onWindowClick);
    this.offBaseSelectClickEvent();
    this.$emit("update:modelValue", "");
  },

  methods: {
    reset() {
      this.search = "";
      this.loadMore = true;
      this.selectedItem = undefined;
      this.options = [];
    },

    async removeFromSelected(index: number) {
      if (Array.isArray(this.selectedItem)) {
        const item = { ...this.selectedItem[index] };

        this.selectedItem.splice(index, 1);

        this.$emit(
          "update:modelValue",
          this.selectedItem.map((item) => item[this.keyValue])
        );

        await this.$nextTick();

        if (this.removedSelectedItem) {
          this.removedSelectedItem(item);
        }
      }
    },

    isItemActive(item: object) {
      return (
        (this.selectedItem &&
          this.selectedItem?.[this.keyValue] == item[this.keyValue]) ||
        (Array.isArray(this.selectedItem) &&
          this.selectedItem.find(
            (currItem) => currItem[this.keyValue] === item[this.keyValue]
          ))
      );
    },

    setSelectedItemFromArray(arrs: Array<any>) {
      this.selectedItem = [];

      for (let i = 0; i < arrs.length ?? []; i++) {
        const obj = {};

        obj[this.keyText] = arrs[i][this.keyText];
        obj[this.keyValue] = arrs[i][this.keyValue];

        this.selectedItem.push(obj as BaseItem);

        this.$emit(
          "update:modelValue",
          this.selectedItem.map((item) => item[this.keyValue])
        );
      }
    },

    /**
     * menampilkan text yang terpilih di select
     * berdasarkan nilai modelValue */
    initSelectedItem() {
      if (this.multiple && !Array.isArray(this.modelValue)) {
        toast(
          "error",
          "the model of base select must be an array if multiple props active",
          0
        );

        return console.error(
          "the model of base select must be an array if multiple props active"
        );
      }

      if (typeof this.modelValue === "object") {
        if (Array.isArray(this.modelValue)) {
          this.setSelectedItemFromArray([...this.modelValue]);
        } else {
          this.selectedItem = { ...this.modelValue } as BaseItem;
        }
      } else {
        this.selectedItem = this.options.find(
          (item) => item[this.keyValue] === this.modelValue
        );
      }
    },

    onItemScroll(e: Event) {
      if (e.target instanceof HTMLElement) {
        if (
          e.target.scrollTop >=
          e.target.scrollHeight - e.target.clientHeight
        ) {
          this.paginateRequest();
        }
      }
    },

    async paginateRequest(reset = false) {
      if (this.resourceHttp != null && this.loadMore && !this.onRequest) {
        this.onRequest = true;
        this.page++;

        if (reset) this.page = 1;

        const { data, has_more } = await this.resourceHttp.paginate({
          ...(this.filterPaginate as BasePaginateFilter),
          search: this.search,
          page: this.page,
        });

        this.options = !reset ? [...this.options, ...data] : (data as any);
        this.loadMore = has_more;
        this.onRequest = false;
      }
    },

    show() {
      this.search = "";
      this.showOption = !this.showOption;
      this.showOption
        ? this.pubSub.trigger(EventNames.BaseSelectClick, this)
        : "";

      this.paginateRequest();
    },

    onWindowClick() {
      this.showOption = false;
    },

    removeItem() {
      this.$emit("update:modelValue", "");
      this.selectedItem = undefined;
    },

    async choose(item: BaseItem) {
      this.showOption = false;

      if (this.multiple && Array.isArray(this.modelValue)) {
        if (!Array.isArray(this.selectedItem)) {
          this.selectedItem = [];
        }

        this.selectedItem.push(item);

        await this.$nextTick();

        this.$emit(
          "update:modelValue",
          this.selectedItem.map((item) => item[this.keyValue])
        );
      } else if (!this.multiple) {
        this.$emit("update:modelValue", item[this.keyValue]);
        this.$emit("change", item);

        await this.$nextTick();
        this.selectedItem = item;
      }
    },
  },

  watch: {
    showOption(val) {
      if (!val) {
        this.options = [];
        this.loadMore = true;
        this.page = 0;
      }
    },
    search() {
      clearTimeout(this.searchTimeoutFn);

      this.searchTimeoutFn = setTimeout(() => {
        this.loadMore = true;
        this.paginateRequest(true);
      }, 300);
    },
    items: {
      deep: true,
      handler(arrs) {
        this.options = arrs;
      },
    },
    modelValue(val) {
      if (!val) {
        this.selectedItem = undefined;
      } else if (!this.multiple) {
        this.selectedItem = this.items.find(
          (item) => item[this.keyValue] === val
        );
      }
    },
  },
});
