import { RouteNames } from "@/enums";
import { ChildrenRecordRaw, Childrens, Meta } from "@/lib/router";
import { Constructor } from "@/types";
import { RouterView } from "vue-router";

/**
 * put the function in your vue component props
 * better in computed
 *  
 */
export function createInstance<T>(theClass: Constructor<T>): () => T {
    let instance: null | T = null;

    return () => {
        if (!instance) {
            instance = new theClass;
        }

        return instance as T;
    };
}

export function createChildrenRecordRaw(route: ChildrenRecordRaw): ChildrenRecordRaw {
    return route;
}

/**
 * base for create CRUD page.
 * the path will create:
 * 
 * basePath/,
 * basePath/create,
 * basePath/edit/:id,
 * basePath/detail/:id, 
 */
export function createBaseChildrenRecordRaw(basePath: string, opt: {
    index: Omit<ChildrenRecordRaw, "path" | "meta"> & { name: RouteNames, component: any, meta?: Meta },
    create?: Omit<ChildrenRecordRaw, "path" | "meta"> & { name: RouteNames, component: any, meta?: Meta },
    edit?: Omit<ChildrenRecordRaw, "path" | "meta"> & { name: RouteNames, component: any, meta?: Meta },
    detail?: Omit<ChildrenRecordRaw, "path" | "meta"> & { name: RouteNames, component: any, meta?: Meta },
    children?: Childrens
}): ChildrenRecordRaw {
    const children: Childrens = [
        ...opt.children ?? [],
        {
            path: "",
            name: opt.index.name,
            component: opt.index.component,
            meta: {
                withContentTools: true,
                ...opt.index.meta,
            }
        },
    ];

    for (let key in opt) {
        if (key !== 'index') {
            let path = key;
            let meta: Meta = {
                breadcrumbs: [key],
                ...opt[key].meta
            };

            if (key === 'edit') {
                path += "/edit/:id";
            }

            if (key === 'detail') {
                path += "/:id";
                meta.withBackButton = true;
            }

            if (key === 'edit' || key === 'create') {
                meta.withBackButton = true;
                meta.withContentFooter = true;
            }

            children.push({
                path: path,
                name: opt[key].name,
                component: opt[key].component,
                meta: {
                    ...meta
                }
            });
        }
    }

    return createChildrenRecordRaw({
        path: basePath,
        component: RouterView,
        meta: {
            rootName: <RouteNames>opt.index.name,
            createName: <RouteNames>opt.create?.name
        },
        children
    });
}

