import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06278b7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.imgSrc,
    class: _normalizeClass(["bg-primary border border-secondary", [_ctx.lg ? 'lg' : '', _ctx.rounded ? 'rounded-full' : 'rounded-md']])
  }, null, 10, _hoisted_1))
}