
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String as PropType<
        "" | "danger" | "warning" | "info" | "primary" | "success"
      >,
      default: ""
    }
  }
});
