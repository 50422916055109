
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import { baseModal, helperMixin } from "@/mixins";
import { defineComponent, PropType } from "vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";

export default defineComponent({
  emits: ["yes"],
  props: {
    type: {
      type: String as PropType<"default" | "warning" |"delete">,
      default: "default"
    }
  },
  mixins: [helperMixin, baseModal],
  components: {
    BaseModal,
    BaseButtonDefault,
    BaseButtonDanger,
    BaseButtonPrimary
  },
  methods: {
    yes() {
      this.$emit('yes');
      this.value = false;
    }
  }
});
